import img1 from "../../assets/images/logos/logo-05.png"
import img2 from "../../assets/images/logos/cosmic_logo_2.png"
import img3 from "../../assets/images/logos/M&B.png"
import img4 from "../../assets/images/logos/logo_ppi_1.png"
import img5 from "../../assets/images/logos/ALMAS_1.png"
import img6 from "../../assets/images/logos/treebo_logo_2.png"


const CompaniesAPI = [
    {
        id: 1,
        name: "Logic Fusion Land Drying Works LLC",
        phone: "+97125555959",
        email: "sales@logicfusionllc.com",
        imageSrc: img1,
        overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.",
        services: [
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-rocket-launch",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-project-management",
                description: "Integer nec odio. Praesent libero. Sed cursus ante dapibus diam."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-delivery",
                description: "Sed nisi. Nulla quis sem at nibh elementum imperdiet."
            }
        ],
        socialLinks: [
            { platform: "facebook", url: "#!" },
            { platform: "twitter", url: "#!" },
            { platform: "linkedin", url: "#!" },
            { platform: "chrome", url: "#!" }
        ],
        location: "UAE",
        website: "https://logicfusionllc.com/"

    },
    {
        id: 2,
        name: "Cosmic Gate Turnery Work Shop",
        phone: "+971 2 555 6062",
        email: "cosmicgateme@gmail.com",
        imageSrc: img2,
        overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.",
        services: [
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-rocket-launch",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-project-management",
                description: "Integer nec odio. Praesent libero. Sed cursus ante dapibus diam."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-delivery",
                description: "Sed nisi. Nulla quis sem at nibh elementum imperdiet."
            }
        ],
        socialLinks: [
            { platform: "facebook", url: "#!" },
            { platform: "twitter", url: "#!" },
            { platform: "linkedin", url: "#!" },
            { platform: "chrome", url: "#!" }
        ],
        location: "UAE",
        website: "http://cosmicgate.biz/"

    },
    {
        id: 3,
        name: "Treebo Rubber Industries",
        phone: "+919947036900",
        email: "sales@treeborubber.com",
        imageSrc: img6,
        overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.",
        services: [
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-rocket-launch",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-project-management",
                description: "Integer nec odio. Praesent libero. Sed cursus ante dapibus diam."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-delivery",
                description: "Sed nisi. Nulla quis sem at nibh elementum imperdiet."
            }
        ],
        socialLinks: [
            { platform: "facebook", url: "#!" },
            { platform: "twitter", url: "#!" },
            { platform: "linkedin", url: "#!" },
            { platform: "chrome", url: "#!" }
        ],
        location: "India",
        website: "https://treeborubber.com/"
    },
    {
        id: 4,
        name: "Al Mas Pumps Trading EST",
        phone: "+00966 59 6464 897",
        email: "sales@almasdwp.com",
        imageSrc: img5,
        overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.",
        services: [
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-rocket-launch",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-project-management",
                description: "Integer nec odio. Praesent libero. Sed cursus ante dapibus diam."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-delivery",
                description: "Sed nisi. Nulla quis sem at nibh elementum imperdiet."
            }
        ],
        socialLinks: [
            { platform: "facebook", url: "#!" },
            { platform: "twitter", url: "#!" },
            { platform: "linkedin", url: "#!" },
            { platform: "chrome", url: "#!" }
        ],
        location: "KSA",
        website: "https://almasdwp.com"
    },
    {
        id: 5,
        name: "Miller & Bauer International",
        phone: "+918591581324",
        email: "info@millerbauer.com",
        imageSrc: img3,
        overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.",
        services: [
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-rocket-launch",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-project-management",
                description: "Integer nec odio. Praesent libero. Sed cursus ante dapibus diam."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-delivery",
                description: "Sed nisi. Nulla quis sem at nibh elementum imperdiet."
            }
        ],
        socialLinks: [
            { platform: "facebook", url: "#!" },
            { platform: "twitter", url: "#!" },
            { platform: "linkedin", url: "#!" },
            { platform: "chrome", url: "#!" }
        ],
        location: "India",
        website: "https://millernbauer.com"
    },
    {
        id: 6,
        name: "Pavali Pumps Industries",
        phone: "+919947036900",
        email: "info@pavalipumps.com",
        imageSrc: img4,
        overview: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.",
        services: [
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-rocket-launch",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-project-management",
                description: "Integer nec odio. Praesent libero. Sed cursus ante dapibus diam."
            },
            {
                title: "Lorem Ipsum",
                iconClass: "flaticon-delivery",
                description: "Sed nisi. Nulla quis sem at nibh elementum imperdiet."
            }
        ],
        socialLinks: [
            { platform: "facebook", url: "#!" },
            { platform: "twitter", url: "#!" },
            { platform: "linkedin", url: "#!" },
            { platform: "chrome", url: "#!" }

        ],
        location: "India",
        website: "https://pavalipumps.com/"

    }
];

export default CompaniesAPI;
