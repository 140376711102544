import React from "react";
import AboutApi2 from "../../api/AboutApi2";
import Carousel5 from "../reactcarouselSection/Carousel5";

const AboutSection2 = () => {
  return (
    <>
      <section className="pb-0" style={{ marginTop: "-100px", marginBottom: "50px" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="theme-title">
                <h6>OUR EXPERTISE</h6>
                <h2>Comprehensive Industrial Solutions Tailored to Your Needs</h2>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            {AboutApi2.map((feature, index) => (
              <div className="col-md-4 col-md-6 col-lg-4 mb-4 d-flex align-items-stretch" key={index}>
                <div
                  className="featured-item style-3"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    padding: "25px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px"
                  }}
                >
                  <div>
                    <div className="featured-icon">
                      <i aria-hidden="true" className={feature.icon}></i>
                    </div>
                    <div className="featured-description">
                      <div className="featured-title">
                        <h5>{feature.title}</h5>
                      </div>
                      <p style={{ textAlign: "left" }}>{feature.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

    </>
  );
};

export default AboutSection2;
