import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo4 from "../../assets/images/logo-04.png"
import {
  Nav,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Collapse,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { FiChevronDown } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import API_BASE_URL from '../../api/API/baseUrl';
import axios from 'axios';
import navLinks from '../../api/NavLinks';



const Header = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isSearch, setSearch] = useState(false);
  const [isCartview, setIsCartview] = useState(false);
  const [inputValue, setInputValue] = useState({ search: "" });
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');


  const [productData, setProductData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productResponse = await axios.get(`${API_BASE_URL}/product`);
        setProductData(productResponse.data.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);



  const onInputChange = (evt) => {
    const { name, value } = evt.target;
    setInputValue((prev) => ({ ...prev, [name]: value }));
  };

  const [quoteData, setQuoteData] = useState({
    name: '',
    email: '',
    mobile: '',
    product: '',
    message: ''
  });

  const onQuoteChange = (evt) => {
    const { name, value } = evt.target;
    setQuoteData((prev) => ({ ...prev, [name]: value }));
  };

  const wrapperRef = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [openMenus, setOpenMenus] = useState([]);

  const toggleMenu = (index) => {
    if (openMenus.includes(index)) {
      setOpenMenus(openMenus.filter((item) => item !== index));
    } else {
      setOpenMenus([...openMenus, index]);
    }
  };

  const closeAllMenus = () => {
    setOpenMenus([]);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsCartview(false);
    }
  };

  const handleScroll = () => {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    if (scrollTop > 100) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (loader) {
      const timeout = setTimeout(() => {
        setLoader(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [loader]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);
    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const search = () => {
    setSearch((prev) => !prev);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    // Reset the message state
    setMessage('');
    setMessageType('');
  };

  const submitQuote = async () => {
    const formData = new FormData();
    formData.append('name', quoteData.name);
    formData.append('email', quoteData.email);
    formData.append('mobile', quoteData.mobile);
    formData.append('product', quoteData.product);
    formData.append('message', quoteData.message);

    try {
      const response = await axios.post(`${API_BASE_URL}/quote`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        setMessage('Quote submitted successfully!');
        setMessageType('success');
        setQuoteData({
          name: '',
          email: '',
          mobile: '',
          product: '',
          message: ''
        });
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      } else {
        setMessage('Failed to submit quote.');
        setMessageType('error');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred while submitting the quote.');
      setMessageType('error');
    }
  };

  return (
    <>
      <header id="site-header" className="header">
        <div className="header-top">
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex align-items-center justify-content-between">
                <div className="topbar-link">
                  <ul className="list-inline d-flex flex-row">
                    <li style={{ display: 'flex', alignItems: 'center' }}>
                      <i className="flaticon flaticon-location" style={{ marginRight: '8px' }} />
                      <span className="text-decoration-none">{data?.coordinates}</span>
                    </li>
                    <li style={{ display: 'flex', alignItems: 'center' }}>
                      <i className="flaticon-chat" style={{ marginRight: '8px' }} />
                      <Link to={`mailto:${data?.email}`} className="text-decoration-none">{data?.email}</Link>
                    </li>
                    <li style={{ display: 'flex', alignItems: 'center', marginTop: '0px' }}>
                      <i className="flaticon-phone-call" style={{ marginRight: '8px' }} />
                      <Link to={`tel:${data?.phone}`} className="text-decoration-none text-reset">+{data?.phone}</Link>
                    </li>

                  </ul>
                </div>
                <div className="d-flex align-items-center" style={{ marginTOp: "8px" }}>
                  <div className="social-icons">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <Link to="/" onClick={(e) => e.preventDefault()}>
                          <i className="lab la-facebook-f" />
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to="/" onClick={(e) => e.preventDefault()}>
                          <i className="lab la-twitter" />
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to="/" onClick={(e) => e.preventDefault()}>
                          <i className="lab la-instagram" />
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to="/" onClick={(e) => e.preventDefault()}>
                          <i className="lab la-linkedin-in" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="header-wrap" className={`${visible ? 'fixed-header ' : ''}`}>
          <div className="container-fluid" style={{ paddingLeft: "25px", paddingRight: "10px" }}>
            <div className="row">
              <div className="col">
                <Nav className="navbar navbar-expand-lg d-flex justify-content-between">
                  <Link className="navbar-brand" to="/" style={{ marginRight: "1px" }}>
                    <h6 className="header-title" style={{ fontSize: "24px", marginTop: "5px" }}>
                      <img src={logo4} style={{ height: "50px", padding: "1px", marginTop: "-2px", marginRight: "5px" }} />
                      LOGIC FUSION LLC
                    </h6>
                  </Link>
                  {(screenSize.width < 991) && toggle ? (
                    <button onClick={toggle} className="navbar-toggler ht-toggler" type="button">
                      <svg width={100} height={100} viewBox="0 0 100 100">
                        <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                        <path className="line line2" d="M 20,50 H 80" />
                        <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                      </svg>
                    </button>
                  ) : (
                    <button className="navbar-toggler ht-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-expanded="true" aria-label="Toggle navigation">
                      <svg width="100" height="100" viewBox="0 0 100 100">
                        <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                        <path className="line line2" d="M 20,50 H 80" />
                        <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                      </svg>
                    </button>
                  )}
                  {(isOpen || (screenSize.width > 991)) && (
                    <Collapse
                      className="collapse navbar-collapse justify-content-center d-block d-lg-none d-xl-block"
                      navbar
                    >
                      {/* {navLinks.map((navLink, index) => (
                        <Nav key={index} className="navbar-nav m-0" navbar>
                          <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav className='m-0' tag={navLink.path ? NavLink : 'button'} to={navLink.path}>
                              {navLink.menu_title}
                              {navLink.child_routes && navLink.child_routes.length > 0 && <FiChevronDown />}
                            </DropdownToggle>
                            {navLink.child_routes && navLink.child_routes.length > 0 && (
                              <DropdownMenu tag="ul">
                                {navLink.child_routes.map((subNavLink, index) => (
                                  <DropdownItem tag="li" className='p-0' key={index}>
                                    <DropdownItem className='dropdown-item-style' tag={NavLink} to={subNavLink.path}>
                                      {subNavLink.menu_title}
                                    </DropdownItem>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            )}
                          </UncontrolledDropdown>
                        </Nav>
                      ))} */}
                      <ul className="navbar-nav ms-auto" style={{ marginTop: "-5px" }}>
                        <li className="nav-item" style={{ fontSize: "15px" }}>
                          <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/about">About</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/products">Products</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/service">Services</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/gallery">Gallery</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="/contact-us">Contact</Link>
                        </li>
                      </ul>
                      <Link
                        onClick={handleShowModal}
                        style={{ marginLeft: "0px", padding: "10px", marginTop: "-5px" }}
                        className="primary-btn text-decoration-none"
                      >
                        Request a Quote
                      </Link>
                    </Collapse>
                  )}
                </Nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <Modal isOpen={showModal} toggle={handleCloseModal} style={{ backgroundColor: "#1a1a1c" }}>
        <ModalHeader
          style={{
            backgroundColor: "#1a1a1c",
            display: 'flex',
            padding: "25px",
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
          toggle={handleCloseModal}>
          <div style={{ marginLeft: "125px" }}> Request a Quote</div>

        </ModalHeader>
        {message && (
          <div style={{ textAlign: 'center', marginTop: '10px', fontWeight: "25px", color: messageType === 'success' ? 'orange' : 'red' }}>
            {message}
          </div>
        )}
        <ModalBody style={{ backgroundColor: "#1a1a1c" }}>
          <Form style={{ padding: "15px" }}>
            <FormGroup>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="Enter your full name"
                value={quoteData.name}
                onChange={onQuoteChange}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email address"
                value={quoteData.email}
                onChange={onQuoteChange}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="tel"
                name="mobile"
                id="mobile"
                placeholder="Enter your phone number"
                value={quoteData.mobile}
                onChange={onQuoteChange}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="select"
                name="product"
                id="product"
                style={{
                  backgroundColor: "#1a1a1c",
                  color: "white",
                  border: "1px solid #1a1a1c",
                  outline: "none",
                  boxShadow: "none",
                  transition: "border-color 0.3s"
                }}
                onFocus={(e) => {
                  e.target.style.border = "1px solid #ff6a00";
                  e.target.style.boxShadow = "0 0 5px #ff6a00";
                }}
                onBlur={(e) => {
                  e.target.style.border = "1px solid #1a1a1c";
                  e.target.style.boxShadow = "none";
                }}
                value={quoteData.product}
                onChange={onQuoteChange}
              >
                <option style={{ backgroundColor: "#1a1a1c", color: "white" }}>Select a product</option>
                {productData?.map((product) => (
                  <option
                    key={product.id}
                    style={{ backgroundColor: "#1a1a1c", color: "white" }}
                    value={product.name}
                  >
                    {product.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <textarea
                name="message"
                id="message"
                placeholder="Enter your message"
                style={{ width: "100%", backgroundColor: "#1a1a1c", color: "white", borderRadius: "5px", padding: "10px" }}
                cols="50"
                rows="5"
                value={quoteData.message}
                onChange={onQuoteChange}
              ></textarea>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter style={{ backgroundColor: "#1a1a1c", justifyContent: 'center' }}>
          <Button
            className='modal-primary-btn'
            onClick={submitQuote}
            style={{ margin: '0 10px' }}
          >
            Send
          </Button>
          <Button
            className='modal-secondary-btn'
            onClick={handleCloseModal}
            style={{ margin: '0 10px' }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>


      <div className={`search-input ${isSearch ? "d-block" : "d-none"}`} id="search-input-box">
        <div className="search-inner-box">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <form role="search" id="search-form" className="search-form d-flex justify-content-between search-inner">
                  <label>
                    <Input type="search" className="search-field" placeholder="Search Here" value={inputValue.search} name="search" onChange={onInputChange} />
                  </label>
                  <Button type="submit" className="search-submit">
                    <i className="las la-search"></i>
                  </Button>
                  <span className="las la-times close-search" id="close-search" title="Close Search" onClick={search}></span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;