import bg1 from '../../assets/images/bg/04.jpg'
import bg2 from '../../assets/images/bg/05.jpg'

const OwlCarouselApi = [
    {
        id: 1,
        image: bg1,
        title: "Expert Dewatering Solutions",
        details: "Unlocking Efficiency: Our range of services includes subcontracting for land drying, along with premium offerings like water filters, dewatering pumps, and equipment.",
        btn1: "Read More",
        btn2: "Get Started",
    },
    {
        id: 2,
        image: bg2,
        title: "Your Dewatering Experts",
        details: "From land drying subcontracting to top-tier equipment supply, we've got your dewatering needs covered. Trust us for efficient solutions and reliable service.",
        btn1: "Read More",
        btn2: "Get Started",
    },
    {
        id: 3,
        image: bg1,
        title: "Efficient Water Management",
        details: "Maximizing Productivity: Our solutions ensure efficient water management, allowing for seamless operations and reduced downtime.",
        btn1: "Read More",
        btn2: "Get Started",
    },
    {
        id: 4,
        image: bg2,
        title: "Reliable Industrial Solutions",
        details: "Ensuring Reliability: With our top-quality equipment and expert services, we provide reliable solutions for all your industrial needs.",
        btn1: "Read More",
        btn2: "Get Started",
    }
];

export default OwlCarouselApi;