const ServicesApi2 = [
    {
        id: 1,
        title: "Export & Import of Dewatering Pumps & Accessories",
        description: "We specialize in the export and import of high-quality dewatering pumps and their accessories, ensuring top-notch performance and reliability.",
        list: [
            "Export & Import of various types of Dewatering Pumps",
            "Wide range of accessories for dewatering solutions",
            "Global shipping and logistics support",
            "Customs and regulatory compliance"
        ],
        listDetails: [
            {
                title: "Export & Import of various types of Dewatering Pumps",
                description: "We offer a diverse range of dewatering pumps to cater to different industries and applications. Our selection includes submersible pumps, centrifugal pumps, trash pumps, wellpoint pumps, and more. Each pump is carefully selected for its quality, performance, and suitability for specific dewatering tasks. Whether you need pumps for construction sites, mining operations, municipal projects, or agricultural drainage, we have the expertise to supply the right equipment for the job.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]
            },
            {
                title: "Wide range of accessories for dewatering solutions",
                description: "In addition to dewatering pumps, we provide a comprehensive range of accessories to enhance the efficiency and effectiveness of your dewatering solutions. Our accessories include hoses, pipes, fittings, couplings, valves, control panels, and monitoring systems. These accessories are designed to work seamlessly with our pumps and can be customized to meet the specific requirements of your project. With our extensive selection of accessories, you can optimize your dewatering operations for maximum productivity and performance.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]
            },
            {
                title: "Global shipping and logistics support",
                description: "We offer reliable global shipping and logistics support to ensure timely delivery of our products to customers around the world. Whether you're located in North America, Europe, Asia, Africa, or elsewhere, we can arrange for the efficient transportation of your dewatering equipment. Our logistics team works with trusted shipping partners to coordinate air, sea, and land freight services, taking care of all the necessary documentation, customs clearance, and delivery arrangements. With our global shipping and logistics support, you can count on us to deliver your pumps and accessories safely and on schedule.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]
            },
            {
                title: "Customs and regulatory compliance",
                description: "We are committed to ensuring full compliance with all customs and regulatory requirements for the export and import of dewatering pumps and accessories. Our team stays up-to-date with the latest regulations and standards governing international trade, including customs tariffs, import/export restrictions, product certifications, and environmental regulations. We provide comprehensive support to help you navigate the complexities of customs clearance and regulatory compliance, including assistance with documentation, permits, licenses, and inspections. With our expertise in customs and regulatory compliance, you can trust us to facilitate smooth and hassle-free transactions for your dewatering equipment.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]
            }
        ],
        images: [
            "https://media.licdn.com/dms/image/D5622AQEO-6pec6_urQ/feedshare-shrink_800/0/1697760728716?e=2147483647&v=beta&t=0chXfAbGKJKEWVHhhlXzgV80scGy90gFqo06Vry9YRo",
            "https://via.placeholder.com/400x300"
        ]
    },
    {
        id: 2,
        title: 'Manufacturing of Spare Parts for Dewatering Pumps',
        description: 'Our manufacturing division produces high-quality spare parts for a range of dewatering pumps and associated equipment.',
        list: [
            'Spare parts for Dewatering Pumps (GEHO, SYKES, MILLER, NG, OASIS, GEOVAC)',
            'Custom-designed impellers and casings',
            'Hydraulic Hoses & Fittings for various applications',
            'Industrial Hoses & Assembly'
        ],
        listDetails: [
            {
                title: "Spare parts for Dewatering Pumps (GEHO, SYKES, MILLER, NG, OASIS, GEOVAC)",
                description: "We manufacture a wide range of spare parts for popular dewatering pump brands, including GEHO, SYKES, MILLER, NG, OASIS, and GEOVAC. Our spare parts are precision-engineered to meet the highest standards of quality and reliability, ensuring optimal performance and longevity for your pumps.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: "Custom-designed impellers and casings",
                description: "In addition to standard spare parts, we also offer custom-designed impellers and casings tailored to your specific requirements. Our engineering team can work with you to develop bespoke solutions that optimize the performance and efficiency of your dewatering pumps, ensuring maximum productivity and cost-effectiveness.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: "Hydraulic Hoses & Fittings for various applications",
                description: "We provide a wide range of hydraulic hoses and fittings suitable for various applications in the dewatering industry. Whether you need high-pressure hoses for heavy-duty pumping tasks or specialized fittings for specific equipment configurations, we have you covered. Our hydraulic hoses and fittings are durable, reliable, and designed to withstand the rigors of demanding operating conditions.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: "Industrial Hoses & Assembly",
                description: "In addition to hydraulic hoses, we also offer a comprehensive range of industrial hoses for various applications, including water transfer, chemical handling, and material conveyance. We provide complete hose assembly services, including cutting, crimping, and coupling, to ensure a perfect fit for your specific requirements. Our industrial hoses are built to last, offering superior performance and durability in the most challenging environments.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            }
        ],
        images: [
            'https://www.mbexports.com/uploads/gallery/Xjf9TbLQFg.JPG',
            'https://via.placeholder.com/400x300'
        ]
    },
    {
        id: 3,
        title: 'Hydraulic & Industrial Hoses & Assembly',
        description: 'We offer a comprehensive range of hydraulic and industrial hoses, providing complete assembly solutions.',
        list: [
            'High-pressure hydraulic hoses',
            'Industrial hoses for various applications',
            'Custom hose assembly services',
            'Maintenance and repair of hose assemblies'
        ],
        listDetails: [
            {
                title: "High-pressure hydraulic hoses",
                description: "Our high-pressure hydraulic hoses are designed to handle the rigors of heavy-duty pumping tasks, offering superior strength, flexibility, and durability. Whether you need hoses for hydraulic systems, construction equipment, or industrial machinery, we have the right solution for your application. Our high-pressure hoses are tested to ensure they meet the highest standards of quality and performance, providing reliable operation in demanding environments.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: "Industrial hoses for various applications",
                description: "We supply a wide range of industrial hoses suitable for a variety of applications, including water transfer, chemical handling, material conveyance, and more. Our industrial hoses are available in various sizes, materials, and configurations to meet the diverse needs of different industries. Whether you require hoses for mining, agriculture, manufacturing, or construction, we have the expertise to provide the right solution for your specific requirements.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: "Custom hose assembly services",
                description: "In addition to supplying hoses, we offer custom hose assembly services to provide complete solutions for your hydraulic and industrial needs. Our experienced technicians can assemble hoses to your exact specifications, ensuring proper sizing, fitting, and compatibility with your equipment. With our custom hose assembly services, you can minimize downtime, optimize performance, and enhance the safety and reliability of your operations.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: "Maintenance and repair of hose assemblies",
                description: "We also offer maintenance and repair services for hydraulic and industrial hose assemblies to keep your equipment operating at peak performance. Our technicians are trained to identify and address common issues such as leaks, abrasion, and corrosion, ensuring your hoses remain in optimal condition. Whether you need routine maintenance or emergency repairs, we have the expertise and resources to keep your operations running smoothly.",
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            }
        ],
        images: [
            'https://africanminingmarket.com/wp-content/uploads/2022/12/atlas_copco_pac_ipr.jpg',
            'https://via.placeholder.com/400x300'
        ]
    },
    {
        id: 4,
        title: 'Rental & Repairing of Dewatering Pumps & Accessories',
        description: 'We offer rental services and expert repair solutions for dewatering pumps and accessories, ensuring minimal downtime.',
        list: [
            'Rental services for a wide range of Dewatering Pumps',
            'Expert repair services for pumps and accessories',
            'Preventive maintenance and servicing',
            'On-site support and troubleshooting'
        ],
        listDetails: [
            {
                title: 'Rental services for a wide range of Dewatering Pumps',
                description: 'We provide rental services for a wide range of dewatering pumps to meet the temporary needs of your projects. Whether you require pumps for short-term dewatering tasks or emergency situations, we have the right equipment for the job. Our rental fleet includes various types and sizes of pumps, ensuring we can accommodate your specific requirements quickly and efficiently.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: 'Expert repair services for pumps and accessories',
                description: 'Our team of skilled technicians specializes in repairing dewatering pumps and accessories to restore them to optimal condition. Whether your equipment has suffered from mechanical failures, electrical issues, or other problems, we have the expertise to diagnose and repair the issue promptly. We use genuine parts and follow manufacturer\'s guidelines to ensure quality repairs and reliable performance.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: 'Preventive maintenance and servicing',
                description: 'In addition to repair services, we offer preventive maintenance programs to help extend the lifespan of your dewatering pumps and accessories. Our maintenance plans include regular inspections, lubrication, cleaning, and testing to identify and address potential issues before they lead to costly downtime. By proactively maintaining your equipment, you can minimize the risk of unexpected failures and maximize uptime.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: 'On-site support and troubleshooting',
                description: 'We provide on-site support and troubleshooting services to address any issues or concerns you may have with your dewatering equipment. Our technicians are available to assist you with installation, commissioning, operation, and maintenance of your pumps and accessories. Whether you need technical assistance, training, or emergency repairs, we are here to help you maximize the performance and reliability of your equipment.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            }
        ],
        images: [
            'https://www.canadiandewatering.com/files/cdig/2017-09/land-pump_0.jpg',
            'https://via.placeholder.com/400x300'
        ]
    },
    {
        id: 5,
        title: 'Foundry Section: Casting & Molding',
        description: 'Our foundry section specializes in casting and molding using various metals, delivering high-precision components.',
        list: [
            'Cast Iron, Brass, and Bronze casting',
            'SS 304/316, Copper, Mild Steel, and Aluminum molding',
            'Custom casting solutions for complex designs',
            'Heat treatment and finishing services'
        ], listDetails: [
            {
                title: 'Cast Iron, Brass, and Bronze casting',
                description: 'We specialize in the casting of various metals, including cast iron, brass, and bronze. Our foundry uses advanced techniques to produce high-quality castings with precise dimensions and excellent surface finishes. Whether you need small or large castings, simple or complex shapes, we have the expertise to deliver components that meet your exact specifications and quality standards.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: 'SS 304/316, Copper, Mild Steel, and Aluminum molding',
                description: 'In addition to traditional casting materials, we also offer molding services for stainless steel (SS 304/316), copper, mild steel, and aluminum. Our skilled craftsmen use state-of-the-art equipment and techniques to mold these materials into various shapes and sizes according to your requirements. Whether you need precision parts for industrial machinery, automotive components, or architectural elements, we can provide cost-effective molding solutions tailored to your needs.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: 'Custom casting solutions for complex designs',
                description: 'We specialize in providing custom casting solutions for complex designs and intricate geometries. Our experienced engineers work closely with you to understand your design requirements and develop casting processes that ensure the production of high-quality components with tight tolerances and minimal defects. Whether you have a challenging design or a unique application, we have the capabilities to deliver reliable and cost-effective casting solutions.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: 'Heat treatment and finishing services',
                description: 'In addition to casting and molding, we offer heat treatment and finishing services to enhance the mechanical properties and appearance of your components. Our heat treatment processes include annealing, quenching, tempering, and case hardening, depending on the material and application requirements. We also provide a variety of finishing options, such as machining, grinding, polishing, painting, and plating, to meet your specific surface finish and aesthetic preferences.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            }
        ],
        images: [
            'https://www.clinchrivercasting.com/wp-content/uploads/clinch-river-casting-15-e1522414307756.jpg',
            'https://via.placeholder.com/400x300'
        ]
    },
    {
        id: 6,
        title: 'Machining Milling and Fabrication',
        description: 'Our machining section handles all kinds of machining jobs, producing high-quality tools and dies with precision.',
        list: [
            'Comprehensive machining jobs',
            'Production of high-quality tools and dies',
            'Precision milling and turning services',
            'Custom fabrication solutions for various industries'
        ], listDetails: [
            {
                title: 'Comprehensive machining jobs',
                description: 'We offer a wide range of machining services to meet the diverse needs of our customers. Our capabilities include turning, milling, drilling, grinding, and more. Whether you need simple parts or complex components, we have the expertise and equipment to deliver precision machining solutions tailored to your specifications.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]
            },
            {
                title: 'Production of high-quality tools and dies',
                description: 'Our skilled machinists and engineers specialize in producing high-quality tools and dies for various industries. From single prototypes to large-scale production runs, we can manufacture tools and dies that meet your exact requirements. We use advanced machining techniques and state-of-the-art equipment to ensure exceptional accuracy and repeatability in every project.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: 'Precision milling and turning services',
                description: 'We excel in providing precision milling and turning services for a wide range of materials, including metals, plastics, and composites. Our CNC machining centers and lathes are equipped with advanced features for tight tolerances and complex geometries. Whether you need prototypes, small batches, or high-volume production, we can deliver precision-machined components with superior surface finish and dimensional accuracy.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            },
            {
                title: 'Custom fabrication solutions for various industries',
                description: 'In addition to machining, we offer custom fabrication solutions to meet the unique needs of different industries. Our experienced fabricators can work with a variety of materials, including steel, aluminum, stainless steel, and more. Whether you require welding, bending, cutting, or assembly, we have the capabilities to fabricate custom components and structures according to your specifications.',
                images: [
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300",
                    "https://via.placeholder.com/400x300"
                ]            }
        ],
        images: [
            'https://www.clinchrivercasting.com/wp-content/uploads/clinch-river-casting-15-e1522414307756.jpg',
            'https://via.placeholder.com/400x300'
        ]
    },
];

export default ServicesApi2;


