const Information1APi = [
    {
        id: 1,
        icon: "la la-search", 
        title: "Comprehensive Site Assessment",
        details: "Conducting a thorough evaluation to understand specific dewatering requirements and site conditions.",
    },
    {
        id: 2,
        icon: "la la-puzzle-piece", 
        title: "Custom Solution Developement",
        details: "Utilizing award-winning expertise to create customized dewatering plans optimized for project-specific needs.",
    },
    {
        id: 3,
        icon: "la la-cogs", 
        title: "Professional Installation and Setup",
        details: "Expert handling of pump installation and system setup to ensure safety, efficiency, and compliance with standards.",
    },
    {
        id: 4,
        icon: "la la-wrench", 
        title: "Proactive Monitoring and Maintenance",
        details: "Continuous monitoring and proactive maintenance services to sustain peak performance and reliability of dewatering systems.",
    },
];

export default Information1APi;