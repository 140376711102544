import React from "react";
import AboutApi1 from "../../api/AboutApi1";
import AgencySvg from "../svg/Agency";
import { NavLink } from "react-router-dom";

const AboutSection = ({ img, img2, img3, imgStyle }) => {
  return (
    <>
      <section className="about-section pt-0" style={{ paddingBottom: "50px" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 position-relative">
              <img
                className={`img-fluid rounded-bottom${imgStyle}`}
                src={img}
                alt="Img1"
                style={{ borderRadius: "25px", width: "100%", height: "600px" }}
              />
              {/*  {imgStyle ? "" : <div className="round-text">
                <AgencySvg label={"Industrial Focus  •  Global Reach  • "} icon={"flaticon-brand"}/>
              </div>} */}
            </div>
            <div className="col-12 col-lg-6 ps-lg-5 mt-5 mt-lg-0">
              <div className="theme-title">
                <h6>About Us</h6>
                <h3>Your Comprehensive Partner in Dewatering Solutions and Industrial Services </h3>
                <p className="justify-text">
                  We are a dynamic company specializing in a wide array of services catering to the dewatering industry and industrial needs. With a strong focus on quality, reliability, and innovation, we excel in export and import of dewatering pumps and accessories, spare parts manufacturing, hydraulic hoses and fittings, rental and repairing services, and much more. Our diverse offerings also extend to manufacturing and trading in UPVC well points, PVC pipes, truck spare parts, and various industrial equipment.
                </p>
              </div>
              <div className="row my-4">
                {AboutApi1.map((item, index) => (
                  <div key={index} className="col-md-6">
                    <ul style={{ textAlign: "left", paddingLeft: "0", listStyleType: "none" }}>
                      <li>
                        <i className="la la-check-circle" style={{ color: "#ff6a00", marginRight: "10px" }}></i>
                        {item.details}
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
              {/*    <NavLink className="rounded-button ">
                <i className="flaticon-forward" />
              </NavLink>  */}
            </div>
          </div>
        </div>
      </section>
      <section className="about-section pt-0" style={{ paddingBottom: "50px" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 ps-lg-5 mt-5 mt-lg-0">
              <div className="theme-title ">
                <h3>Innovative Dewatering Solutions for Every Industry</h3>
                <p className="justify-text">
                  At our core, we specialize in pioneering dewatering solutions tailored to diverse
                  industrial needs. Our advanced range includes high-performance submersible
                  pumps, centrifugal pumps, and piston pumps, meticulously designed to excel in
                  water extraction, drainage, and sewage management tasks. Beyond exceptional
                  products, we provide comprehensive maintenance, repair, and rental services to
                  optimize equipment performance and durability. With a steadfast commitment
                  to innovation and reliability, we ensure our solutions meet and exceed your
                  expectations, fostering operational efficiency and success in every project.
                  Partner with us to experience the difference of cutting-edge technology and
                  dedicated service in your dewatering operations.                </p>
              </div>
              {/*    <NavLink className="rounded-button ">
              <i className="flaticon-forward" />
            </NavLink>  */}
            </div>
            <div className="col-12 col-lg-6 position-relative">
              <img
                className={`img-fluid rounded-bottom${imgStyle}`}
                src={img2}
                alt="Img1"
                style={{ borderRadius: "25px", width: "100%", height: "400px" }}
              />
              {/*  {imgStyle ? "" : <div className="round-text">
                <AgencySvg label={"Industrial Focus  •  Global Reach  • "} icon={"flaticon-brand"}/>
              </div>} */}
            </div>
          </div>
        </div>
      </section>
      <section className="about-section pt-0" style={{ paddingBottom: "80px" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 position-relative">
              <img
                className={`img-fluid rounded-bottom${imgStyle}`}
                src={img3}
                alt="Img1"
                style={{ borderRadius: "25px", width: "100%", height: "400px" }}
              />
              {/*  {imgStyle ? "" : <div className="round-text">
                <AgencySvg label={"Industrial Focus  •  Global Reach  • "} icon={"flaticon-brand"}/>
              </div>} */}
            </div>
            <div className="col-12 col-lg-6 ps-lg-5 mt-5 mt-lg-0">
              <div className="theme-title">
                <h3>Delivering Excellence: Your Trusted Partner in Quality Solutions</h3>
                <p className="justify-text">
                  At our company, excellence in quality and service defines our commitment to
                  every customer. We are dedicated to delivering top-notch products and services
                  that surpass industry standards. Our skilled professionals work diligently to
                  ensure each project is executed with precision and meticulous attention to detail.
                  Whether you require dewatering pumps, spare parts, or maintenance services,
                  count on us to provide reliable solutions that consistently exceed expectations.
                </p>
              </div>
              {/*    <NavLink className="rounded-button ">
                <i className="flaticon-forward" />
              </NavLink>  */}
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default AboutSection;
