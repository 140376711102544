import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ProjectApi3 from "../../api/ProjectApi/ProjectApi3";
import Sidebar from "../../Components/sideBar/Sidebar";
import SidebarProd from "../../Components/sideBar/SideBarProd";
import axios from "axios";
import API_BASE_URL from "../../api/API/baseUrl";
import API_IMAGE_URL from "../../api/API/baseImageUrl";


const TeamDetails = () => {
    const [data, setData] = useState(null);
    const [categoryData, setCategoryData] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null)
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null)
    const [products, setProducts] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const productResponse = await axios.get(`${API_BASE_URL}/product`);
                setData(productResponse.data.data);

                const categoryResponse = await axios.get(`${API_BASE_URL}/category`);
                const categories = categoryResponse.data.data;
                setCategoryData(categories);

                if (categories?.length > 0) {
                    const defaultCategory = categories[0];
                    setSelectedSubCategory(defaultCategory.name);
                    setSelectedSubCategoryId(defaultCategory.id);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);


    const handleSubCategorySelect = (categoryId) => {
        const selectedCategory = categoryData.find(category => category.id === categoryId);
        setSelectedSubCategory(selectedCategory.name);
        setSelectedSubCategoryId(selectedCategory.id)
    };

    useEffect(() => {
        if (data && selectedSubCategoryId) {
            const filteredProducts = data.filter(item => item.category_id === selectedSubCategoryId);
            setProducts(filteredProducts);
        }
    }, [data, selectedSubCategoryId]);


    if (!data) {
        return (
            <>
                <section>
                    <div className="row justify-content-center text-center mb-5">
                        <div className="col-lg-8 col-md-12">
                            <div className="theme-title">
                                <h6>Leading Experts in Dewatering Pumps & Spares</h6>
                                <h2>Elevate Your Experience with Cutting-Edge Pump Solutions</h2>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '50vh',
                        padding: '20px',
                    }}>
                        <div style={{
                            textAlign: 'center',
                            backgroundColor: 'black',
                            color: 'white',
                            padding: '20px',
                            borderRadius: '10px',
                            fontSize: "24px"
                        }}>
                            Products not found
                        </div>
                    </div>
                </section>
            </>);
    }


    return (
        <section>
            <div className="row justify-content-center text-center mb-5">
                <div className="col-lg-8 col-md-12">
                    <div className="theme-title">
                        <h6>Leading Experts in Dewatering Pumps & Spares</h6>
                        <h2>Elevate Your Experience with Cutting-Edge Pump Solutions</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 mt-10">
                        <SidebarProd
                            subCategories={categoryData}
                            onSubCategorySelect={handleSubCategorySelect}
                        />
                    </div>
                    <div className="col-lg-9">
                        <h3 className="text-center mt-3 mb-2">{selectedSubCategory?.toUpperCase()}</h3>
                        <div className="team-description text-center mt-3">
                            {products && products.length > 0 && (
                                <div>
                                    <div className="row">
                                        {products.map(item => (
                                            <div className="col-lg-6 mb-4" key={item.id} >
                                                <div
                                                    className="featured-item style-3 text-white"
                                                    style={{
                                                        transition: "transform 0.3s, box-shadow 0.3s",
                                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        e.currentTarget.style.transform = "translateY(-10px)";
                                                        e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
                                                        e.currentTarget.querySelector('.card-title').style.color = "#ff6a00";
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.currentTarget.style.transform = "translateY(0)";
                                                        e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
                                                        e.currentTarget.querySelector('.card-title').style.color = "white";
                                                    }}
                                                >
                                                    <img src={API_IMAGE_URL + `${item.img1}`} className="card-img-top" style={{ borderRadius: "25px", height: "300px", objectFit: "cover" }} alt={item.title} />
                                                    <div className="card-body style-3">
                                                        <h5 className="card-title mt-2">{item.name}</h5>
                                                        <NavLink to={`/products/${item.id}`} style={{ padding: "8px" }} className="primary-btn mt-2">View Details</NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamDetails;
