import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import portfolioApi from '../../api/portfolioApi/portfolioApi';
import { HowWeWorks } from '../../api/HowWeWorks/HowWeWorks';
import HeroSection4 from '../heroSection/HeroSection4';

const Portfolio = () => {
    const { index } = useParams();
    const [portfolioItem, setPortfolioItem] = useState(null);
    const [howWeWorkItem, setHowWeWorkItem] = useState(null);

    useEffect(() => {
        const item = JSON.parse(localStorage.getItem('portfolio'));
        if (item) {
            setPortfolioItem(item);
        } else if (index) {
            setPortfolioItem(portfolioApi[index - 1]);
        }

        if (index) {
            const howWeWorkItem = HowWeWorks.find(work => work.index === parseInt(index));
            setHowWeWorkItem(howWeWorkItem);
        }
    }, [index]);

    if (!portfolioItem || !howWeWorkItem) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <HeroSection4 name={portfolioItem.fild} name2={"Home"} />
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="portfolio-image">
                                {portfolioItem.img ? (
                                    <img className="img-fluid product-img" src={portfolioItem.img} alt="Img1" />
                                ) : (
                                    <img className="img-fluid" src={require("../../assets/images/portfolio/01.jpg").default} alt="Img1" />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-8">
                        <div className="col-lg-8 col-12 pe-lg-10">
                            <h3>{howWeWorkItem.name}</h3>
                            <p>{howWeWorkItem.description}</p>
                            <div className="row mt-4 align-items-center">
                                <div className="col-lg-6">

                                    {
                                        howWeWorkItem.name == "Dewatering Pump" ? <>
                                            <ul className="">
                                                {howWeWorkItem.specifications.map((listItem, listIndex) => (
                                                    <li key={listIndex}>
                                                       {listItem}
                                                    </li>
                                                ))}
                                            </ul>

                                        </> : <img className="img-fluid product-subImg" src={howWeWorkItem.image} alt="Img1" />
                                    }
                                </div>
                                <div className="col-lg-6 mt-5 mt-lg-0">
                                    {
                                        howWeWorkItem.name == "Dewatering Pump" ? <> <h5>Engine Specification</h5>  <ul className="list-unstyled list-icon">
                                            {howWeWorkItem.engine.map((listItem, listIndex) => (
                                                <li key={listIndex}>
                                                    <i className="las la-check-circle" /> {listItem}
                                                </li>
                                            ))}
                                        </ul> </> : <> <h5>Specification</h5>
                                            <ul className="list-unstyled list-icon">
                                                {howWeWorkItem.list.map((listItem, listIndex) => (
                                                    <li key={listIndex}>
                                                        <i className="las la-check-circle" /> {listItem}
                                                    </li>
                                                ))}
                                            </ul> </>}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 border-start ps-5 border-light">
                            <div>
                                {portfolioApi.map((item, index) => (
                                    <div className="portfolio-meta-list mb-4" key={index}>
                                        <div className="portfolio-meta-icon">
                                            <i aria-hidden="true" className={`flaticon ${item.icon}`} />
                                        </div>
                                        <div className="portfolio-meta-desc">
                                            <span className="portfolio-meta-title">{item.title}</span>
                                            <span className="portfolio-meta-sub-title">{item.subTitle}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
};

export default Portfolio;

