import React, { useEffect, useState } from "react";
import Header from "./Layout/header/Header";
import { Route, Routes, useLocation } from "react-router-dom";
import "./Vender";
import HomeOne from "./Pages/home/HomeOne";
import Footer from "./Layout/footer/Footer";
import HomeTwo from "./Pages/home/HomeTwo";
import HomeThree from "./Pages/home/HomeThree";
import AboutUs from "./Pages/pages/AboutUs";
import Team from "./Pages/pages/Team";
import TeamSingle from "./Pages/pages/TeamSingle";
import PriceTable from "./Pages/pages/PriceTable";
import Faq from "./Pages/pages/Faq";
import ComingSoon from "./Pages/pages/ComingSoon";
import ErrorPage from "./Pages/pages/ErrorPage";
import UiUxExperience from "./Pages/service/UiUxExperience";
import Portfoliostyleone from "./Pages/portfolio/Portfolio_style_one";
import Portfoliostyletwo from "./Pages/portfolio/Portfolio_style_two";
import Portfoliostylethree from "./Pages/portfolio/portfolio_style_three";
import Portfoliosingle from "./Pages/portfolio/portfolio_single";
import Portfoliosingle2 from "./Pages/portfolio/portfolio_single2";
import Portfoliosingle3 from "./Pages/portfolio/portfolio_single3";
import BlogStyle1 from "./Pages/blogs/Blog_Style_1";
import Blogleftsidebar from "./Pages/blogs/Blog_Left_Sidebar";
import BlogRightSidebar from "./Pages/blogs/Blog_Right_Sidebar";
import BlogSingle from "./Pages/blogs/Blog_Single";
import ContactUs from "./Pages/ContactUs/ContactUs";
import DigitalMarketing from "./Pages/service/DigitalMarketing";
import MobileDevelopment from "./Pages/service/MobileDevelopment";
import SocialMediaMarketing from "./Pages/service/SocialMediaMarketing";
import WebsiteDevelopment from "./Pages/service/WebsiteDevelopment";
import BusinessStrategy from "./Pages/service/BusinessStrategy";
import DeWateringPumps from "./Pages/service/OurProducts";
import CentrifugalProcessPumps from "./Pages/service/CentrifugalProcessPumps";
import CentrifugalWaterPumps from "./Pages/service/CentrifugalWarerPump";
import BoosterPumps from "./Pages/service/BoosterPumps";
import JetPumps from "./Pages/service/JetPumps";
import LayflatHose from "./Pages/service/LayflatHose";
import RubberSuctionHose from "./Pages/service/RubberSuctionHose";
import ReinforcedClearHose from "./Pages/service/ReinforcedClearHose";
import CastIronWorks from "./Pages/service/CastIronWorks";
import GearManafacturing from "./Pages/service/GearManafacturing";
import BrassBronzeCasting from "./Pages/service/BrassBronzeCasting";
import AluminumAlloy from "./Pages/service/AluminumAlloy";
import PrecisionMachining from "./Pages/service/PrecisionMachining";
import HeatTreatment from "./Pages/service/HeatTreatment";
import MetalFabrication from "./Pages/service/MetalFabrication";
import Impellers from "./Pages/service/spareParts/Impellers";
import Seals from "./Pages/service/spareParts/Seals";
import Shafts from "./Pages/service/spareParts/Shafts";
import Rentals from "./Pages/service/rentalsandrepairs/PumpRental";
import Accessories from "./Pages/service/rentalsandrepairs/Accessories";
import RepairServices from "./Pages/service/rentalsandrepairs/RepairServices";
import Services2 from "./Components/servicesSection/CompanyGroup";
import CompaniesPage from "./Pages/pages/Companies";
import Products from "./Pages/pages/Products";
import { SideMenu } from "./api/SideMenu";
import BlogSection2 from "./Components/blogSection/BlogSection2";
import ServiceDetailsPage from "./Pages/service/ServiceDetailsPage";
import API_BASE_URL from "./api/API/baseUrl";
import axios from "axios";
import OurProducts from "./Pages/service/OurProducts";

const App = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { pathname } = useLocation();
  // screen change and scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const shouldHideHeaderFooter = ["/coming-soon", "/error-404"].includes(pathname);
  // console.log("🚀 ~ file: App.js:44 ~ App ~ shouldHideHeaderFooter:", shouldHideHeaderFooter)
  // screen change and scroll to top

  //loader
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  //loader

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/about-us`);
        setData(response.data.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading && <div id="ht-preloader">
        <div className="loader clear-loader">
          {/*           <img className="img-fluid" src={require("./assets/images/loader.png")} alt="Lodaer Img" />
 */}
          <h6 className="header-title" style={{ fontSize: "38px" }}>
            LOGIC FUSION LLC
          </h6> </div>
      </div>
      }
      <div class="page-wrapper">
        {!shouldHideHeaderFooter && <Header data={data} />}
        <Routes>
          {/* Home */}
          <Route path="/" element={<HomeOne />} />
          <Route path="/home-two" element={<HomeTwo />} />
          <Route path="/home-three" element={<HomeThree />} />
          {/* Home */}

          {/* Pages */}
          {/* <Route path="/about-us" element={<AboutUs />} /> */}
          <Route path="/about" element={<AboutUs />} />
          <Route path="/companies" element={<CompaniesPage />} />
          <Route path="/service" element={<Team />} />
          <Route path="/service/:id" element={<ServiceDetailsPage />} />
          <Route path="/team-single" element={<TeamSingle />} />
          <Route path="/products" element={<Products />} />
          <Route path="/business-strategy" element={<BusinessStrategy />} />
          <Route path="/products/:productLink" element={<OurProducts />} />
          <Route path="/team/:id" element={<TeamSingle />} />
          <Route path="/pricing" element={<PriceTable />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="*" element={<ErrorPage />} />
          {/* Pages */}

          {/* service */}
          <Route path="/ui-ux-experience" element={<UiUxExperience />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/mobile-development" element={<MobileDevelopment />} />
          <Route path="/social-media-marketing" element={<SocialMediaMarketing />} />
          <Route path="/website-development" element={<WebsiteDevelopment />} />
          {/* <Route path="/DeWateringPumps" element={< DeWateringPumps />} />
          <Route path="/CentrifugalProcessPumps" element={< CentrifugalProcessPumps />} />
          <Route path="/CentrifugalWaterPumps" element={< CentrifugalWaterPumps />} />
          <Route path="/BoosterPumps" element={<BoosterPumps />} />
          <Route path="/JetPumps" element={< JetPumps />} />
          <Route path="/LayflatHose" element={< LayflatHose />} />
          <Route path="/RubberSuctionHose" element={< RubberSuctionHose />} />
          <Route path="/ReinforcedClearHose" element={< ReinforcedClearHose />} />
          <Route path="/CastIronWorks" element={< CastIronWorks />} />
          <Route path="/GearManufacturing" element={< GearManafacturing />} />
          <Route path="/BrassBronzeCasting" element={< BrassBronzeCasting />} />
          <Route path="/AluminumAlloyCasting" element={< AluminumAlloy />} />
          <Route path="/PrecisionMachining" element={< PrecisionMachining />} />
          <Route path="/HeatTreatment" element={< HeatTreatment />} />
          <Route path="/MetalFabrication" element={< MetalFabrication />} />
          <Route path="/Impellers" element={< Impellers />} />
          <Route path="/MechanicalSeals" element={< Seals />} />
          <Route path="/PumpShafts" element={< Shafts />} />
          <Route path="/PumpRentals" element={< Rentals />} />
          <Route path="/PumpAccessories" element={< Accessories />} />
          <Route path="/PumpRepairs" element={< RepairServices />} /> */}


          {/* service */}

          {/* portfolio */}
          <Route path="/portfolio-style-one" element={<Portfoliostyleone />} />
          <Route path="/portfolio-style-two" element={<Portfoliostyletwo />} />
          <Route path="/portfolio-single" element={<Portfoliosingle />} />
          <Route path="/products/:index" element={<Portfoliosingle />} />
          <Route path="/portfolio-style-one" element={<Portfoliostyleone />} />
          <Route path="/gallery" element={<Portfoliostylethree />} />
          <Route path="/gallery/:categoryId/:id" element={<Portfoliosingle2 />} />
          <Route path="/gallery/:categoryId/:subCatId/:id" element={<Portfoliosingle3 />} />


          {/* portfolio */}

          {/* Blog */}
          <Route path="/blog-style-one" element={<BlogStyle1 />} />
          <Route path="/blog-left-sidebar" element={<Blogleftsidebar />} />
          <Route path="/blog-right-sidebar" element={<BlogRightSidebar />} />
          <Route path="/blog-single" element={<BlogSingle />} />
          <Route path="/blog-single/:id" element={<BlogSingle />} />
          {/* Blog */}

          {/* ContactUs */}
          <Route path="/contact-us" element={<ContactUs data={data} />} />
          {/* ContactUs */}
        </Routes>

        {!shouldHideHeaderFooter && <Footer data={data} />}
      </div>
    </>
  );
};

export default App;
