const AboutApi3 = [
  {
    id: 1,
    icon: 'la la-water',
    title: 'Advanced Dewatering Pumps',
    description: 'Explore our range of high-efficiency pumps engineered for various applications,ensuring reliable performance.',
  },
  {
    id: 2,
    icon: 'la la-cube',
    title: 'Essential Accessories',
    description: 'Discover accessories that optimize the functionality and durability of our dewatering pumps, enhancing operational efficiency',
  },
  {
    id: 3,
    icon: 'la la-tools',
    title: 'Dedicated Service & Maintenance',
    description: 'Benefit from our comprehensive service offerings designed to maximize the lifespan and efficiency of your equipment.',
  },
  {
    id: 4,
    icon: 'la la-cogs',
    title: 'Expert Repair & Spare Parts',
    description: 'Access genuine spare parts and expert repair services to keep your equipment operating smoothly and minimize disruptions.',
  },
];

export default AboutApi3;
