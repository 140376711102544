/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Odometer from 'react-odometerjs';
import ProgressBar from '../common/ProgressBar';
import { NavLink, useNavigate } from 'react-router-dom';
import Sidebar from '../sideBar/Sidebar';
import Sidebar2 from '../sideBar/Sidebar2';
import AboutUsApi from '../../api/AboutUsApi';
import Services5 from '../servicesSection/AccreditationsAndMembership';
import Timeline from '../TimeLine/timeLine';
import AboutInfoOne from './AboutInfoOne';
import MissionValuesGoalsSection from './AboutInfoThree';
import CompanyGroup from '../servicesSection/CompanyGroup';
import AccreditationsAndMembership from '../servicesSection/AccreditationsAndMembership';
import AboutInfoTwo from './AboutInfoTwo';
import FuturePlans from './FuturePlans';



const AboutSection3 = () => {
    const [value, setValue] = useState(0);
    const [limit, setLimit] = useState(false);

    const handleInfinitescroll = async () => {
        try {
            if (document.documentElement.scrollTop >= 200) {
                setLimit(true)
            } else if (document.documentElement.scrollTop === 0) {
                setLimit(false)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleInfinitescroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleInfinitescroll);
        };
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => setValue(23), 1000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <AboutInfoOne />
                    <div className="col-md-12">
                        <CompanyGroup />
                    </div>
                    <div className="col-md-12">
                        <MissionValuesGoalsSection />
                    </div>
                    {/* <section style={{ paddingTop: "5px", paddingBottom: "5px" }} className="testimonial-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mt-5">
                                    <Timeline />
                                </div>
                            </div>
                        </div>
                    </section> */}
                    <div className="col-md-12">
                        <FuturePlans />
                    </div>
                    {/* <div className="col-md-12">
                        <AccreditationsAndMembership />
                    </div> */}

                </div>
            </div>
        </div>
    );
}

export default AboutSection3;
