/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import API_BASE_URL from '../../api/API/baseUrl';
import axios from 'axios';
import Map from './Map';

const ContactUsSection = ({ data }) => {
    const [message, setMessage] = useState('');
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [inputValue, setInputValue] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        location: "",
        message: "",
        product: "",
        postcode: "",
    });

    const [firstName, setFirstName] = useState('');

    const onInputChange = (evt) => {
        const { name, value } = evt.target;
        setInputValue((prev) => ({ ...prev, [name]: value }));
    };

    const onSubmit = async (evt) => {
        evt.preventDefault();
        const combinedName = `${inputValue.firstName} ${inputValue.lastName}`;
        if (!isValidInput(inputValue, combinedName)) {
            alert('Please fill in all required fields.');
            return;
        }

        const formData = new FormData();
        formData.append('name', combinedName);
        formData.append('email', inputValue.email);
        formData.append('mobile', inputValue.mobile);
        formData.append('location', inputValue.location);
        formData.append('message', inputValue.message);
        formData.append('product', inputValue.product);
        formData.append('postcode', inputValue.postcode);

        try {
            const response = await axios.post(`${API_BASE_URL}/contact-us`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 200) {
                setMessage('Message sent successfully!');
                setIsMessageVisible(true);
                setInputValue({
                    firstName: "",
                    lastName: "",
                    email: "",
                    mobile: "",
                    location: "",
                    message: "",
                    product: "",
                    postcode: "",
                });
                setTimeout(() => {
                    setMessage('');
                    setIsMessageVisible(false);
                }, 2000);
            } else {
                setMessage('Failed to send message.');
                setIsMessageVisible(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred while sending the message.');
            setIsMessageVisible(true);
        }
    };

    const isValidInput = (input, name) => {
        return (
            name.trim() !== "" &&
            input.email.trim() !== "" &&
            input.mobile.trim() !== "" &&
            input.location.trim() !== "" &&
            input.message.trim() !== "" &&
            input.product.trim() !== "" &&
            input.postcode.trim() !== ""
        );
    };


    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-6 mb-lg-0 contact-info">
                            <div>
                                <div className="map mb-6" style={{ height: "330px" }}>
                                    <Map />
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <h5>
                                            <i className="flaticon-location" style={{ marginRight: '10px', color: '#ff6a00' }} />
                                            Our Address
                                        </h5>
                                        <p> {data?.address?.split('\n').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                {index !== data?.address?.split('\n').length - 1 && <br />}
                                            </React.Fragment>
                                        ))}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <h5>
                                            <i className="flaticon-chat" style={{ marginRight: '10px', color: '#ff6a00' }} />
                                            Email
                                        </h5>
                                        <p>
                                            <NavLink to={`mailto:${data?.email}`}>{data?.email}</NavLink>
                                        </p>
                                        <h5 style={{marginTop:"-15px"}}>
                                            <i className="flaticon-phone-call" style={{ marginRight: '10px', color: '#ff6a00' }} />
                                            Phone Number
                                        </h5>
                                        <p>
                                            <NavLink to={`tel:+${data?.phone}`}>+{data?.phone}</NavLink>
                                        </p>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h5>Follow Us</h5>
                                        <ul className="list-inline p-0 m-0 social-icons social-colored">
                                            <li className="social-facebook">
                                                <NavLink to="/">
                                                    <i className="la la-facebook" />
                                                </NavLink>
                                            </li>
                                            <li className="social-twitter">
                                                <NavLink to="/">
                                                    <i className="la la-twitter" />
                                                </NavLink>
                                            </li>
                                            <li className="social-instagram">
                                                <NavLink to="/">
                                                    <i className="la la-instagram" />
                                                </NavLink>
                                            </li>
                                            <li className="social-linkedin">
                                                <NavLink to="/">
                                                    <i className="la la-linkedin" />
                                                </NavLink>
                                            </li>
                                            <li className="social-dribbble">
                                                <NavLink to="/">
                                                    <i className="la la-dribbble" />
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 ps-lg-10">
                            <div className="bg-dark-2 p-7 rounded">
                                <div className="theme-title">
                                    <h6>Contact Us</h6>
                                    <h2>Drop A Line!</h2>
                                </div>
                                {isMessageVisible && (
                                    <div className="alert alert-success" role="alert">
                                        {message}
                                    </div>
                                )}
                                <form id="contact-form" method="post" action="/" onSubmit={onSubmit}>
                                    <div className="messages" />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <input id="form_name" type="text" name="firstName" value={inputValue.firstName} className="form-control" placeholder="First Name" required="required" data-error="Name is required." onChange={onInputChange} />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <input id="form_name2" type="text" name="lastName" value={inputValue.lastName} className="form-control" placeholder="Last Name" required="required" data-error="Name is required." onChange={onInputChange} />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <input id="form_email" type="email" name="email" value={inputValue.email} className="form-control" placeholder="Email" required="required" data-error="Valid email is required." onChange={onInputChange} />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <input id="form_phone" type="tel" name="mobile" value={inputValue.mobile} className="form-control" placeholder="Phone" required="required" data-error="Phone is required" onChange={onInputChange} />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <select className="form-select form-control" name='product' value={inputValue.product} aria-label="Default select example" required="required" data-error="Products are required." onChange={onInputChange}>
                                                    <option defaultValue="option1" style={{ backgroundColor: "#1a1a1c", color: "white" }} >Select</option>
                                                    <option style={{ backgroundColor: "#1a1a1c", color: "white" }} value="Products">Products</option>
                                                    <option style={{ backgroundColor: "#1a1a1c", color: "white" }} value="Services">Services</option>
                                                    <option style={{ backgroundColor: "#1a1a1c", color: "white" }} value="Other">Other</option>
                                                </select>
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-5">
                                                <input id="form_location" type="text" name="location" value={inputValue.location} className="form-control" placeholder="Location" required="required" data-error="Location is required." onChange={onInputChange} />
                                                <div className="help-block with-errors" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-5">
                                            <input id="form_postalCode" type="text" name="postcode" value={inputValue.postcode} className="form-control" placeholder="Postal Code" required="required" data-error="Postal Code is required." onChange={onInputChange} />
                                            <div className="help-block with-errors" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-5">
                                            <textarea id="form_message" name="message" className="form-control h-auto" placeholder="Message" rows={4} required="required" data-error="Please, leave us a message." value={inputValue.message} onChange={onInputChange} />
                                            <div className="help-block with-errors" />
                                        </div>
                                    </div>

                                    <div className="mt-5">
                                        <button className="primary-btn" type="submit">Send Message</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactUsSection;