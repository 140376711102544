import React from "react";

import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarouselApi from "../../api/CarouselApi/OwlCarouselApi";
import { NavLink } from "react-router-dom";
const HeroSection1 = () => {
  const options = {
    items: 1,
    nav: false,
    dots: false,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    lazyLoad: true,
    animateOut: "fadeOut",
  };
  return (
    <section className="banner-slider py-0">
      <ReactOwlCarousel
        className="banner-owl-slider owl-carousel no-pb"
        {...options}
      >
        {OwlCarouselApi.map((slide, index) => (
          <div className="item" key={index}>
            <div
              className="slider-img"
              style={{ backgroundImage: `url(${slide.image})` }}
            ></div>
            <div className="banner-content">
              <div className="container">
                <div className="row justify-content-center text-center">
                  <div className="col-xl-10 col-lg-12 col-12">
                    <h1 className="mb-4">{slide.title}</h1>
                    <p>{slide.details}</p>
                    <div className="btn-box mt-5">
                     {/*  <NavLink className="primary-btn" to="/">
                        {slide.btn1}
                      </NavLink>
                      <NavLink className="white-btn" to="/">
                        {slide.btn2}
                      </NavLink> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </ReactOwlCarousel>
    </section>
  );
};
export default HeroSection1;
