import React, { useEffect, useRef, useState } from "react";
import AboutApi1 from "../../api/AboutApi1";
import AgencySvg from "../svg/Agency";
import { NavLink } from "react-router-dom";
import OurMissionApi from "../../api/OurMissionApi";
import img from "../../assets/images/about/about_future.jpg"

const placeholderImageUrl = img;

const FuturePlans = ({ img, imgStyle }) => {
    const [inView, setInView] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setInView(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const fadeInStyle = {
        opacity: inView ? 1 : 0,
        transition: 'opacity 1s ease-in-out',
    };

    return (
        <section className="about-section pt-0" style={{ paddingBottom: "50px" }}>
            <div className="container">
                <div className="row align-items-center" ref={sectionRef} style={fadeInStyle}>
                    <div className="col-12 text-center mb-5">
                        <div className="theme-title">
                            <h6 style={{ fontWeight: "bold", color: "#ff6a00" }}>OUR FUTURE</h6>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 ps-lg-5 mt-5 mt-lg-0 theme-title">
                        <h2 style={{ textAlign: "left", marginTop: "25px" }}>Future Plans and Projects</h2>
                        <p style={{ textAlign: "justify", marginTop: "15px" }}>
                            Looking ahead, we are committed to continuing our pursuit of innovation and excellence in dewatering solutions. Our future projects include:
                        </p>
                        <ul style={{ textAlign: "left", marginTop: "15px", paddingLeft: "0", listStyleType: "none" }}>
                            <li style={{ marginBottom: "10px" }}>
                                <i className="la la-check-circle" style={{ color: "#ff6a00", marginRight: "10px" }}></i>
                                Implementing advanced technology for more efficient operations.
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <i className="la la-check-circle" style={{ color: "#ff6a00", marginRight: "10px" }}></i>
                                Expanding our reach to serve more clients globally.
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <i className="la la-check-circle" style={{ color: "#ff6a00", marginRight: "10px" }}></i>
                                Investing in research and development for sustainable solutions.
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <i className="la la-check-circle" style={{ color: "#ff6a00", marginRight: "10px" }}></i>
                                Enhancing customer service and satisfaction.
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-6 position-relative">
                        <img
                            className={`img-fluid rounded-bottom${imgStyle}`}
                            src={placeholderImageUrl}
                            style={{ borderRadius: "25px", height: "450px", width: "100%", objectFit: "cover", padding: "15px" }}
                            alt="Placeholder Image"
                            onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                            onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FuturePlans;