import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import ServicesApi2 from '../../api/ServicesApi/ServicesApi2';
import WhyChooseUsData from '../../api/WhyChooseUsData';



const ServiceItem = ({ icon, title, description }) => {
    const handleMouseEnter = (e) => {
        e.currentTarget.querySelector('.member-name').style.color = '#ff6a00';
    };

    const handleMouseLeave = (e) => {
        e.currentTarget.querySelector('.member-name').style.color = 'inherit';
    };

    return (
        <div className="item" style={{ transition: 'background-color 0.3s' }}>
            <div
                className="service-item style-3"
                style={{ height: '475px' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="service-icon">
                    <i className={icon}></i>
                </div>
                <div className="service-desc">
                    <h4>
                        <Link className='member-name text-decoration-none'>{title}</Link>
                    </h4>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};



const WhyChooseUs = () => {


    return (
        <section className="pt-0">
            <div className="container">
                <div className="row justify-content-center text-center mb-2">
                    <div className="col-lg-8 col-md-12">
                        <div className="theme-title">
                            <h6>Why Choose Our Dewatering Pump Solutions?</h6>
                            <h2 className="mb-4">Explore the Reasons to Partner with Us</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <OwlCarousel
                            className="owl-carousel"
                            items={3}
                            loop={true}
                            responsive={{
                                0: { items: 1 },
                                576: { items: 2 },
                                992: { items: 3 },
                            }}
                            margin={30}
                            autoplay={true}
                        >
                            {WhyChooseUsData.map((service, index) => (
                                <ServiceItem
                                    key={index}
                                    icon={service.icon}
                                    title={service.title}
                                    description={service.description}
                                />
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyChooseUs;
