import HeroSection4 from "../../Components/heroSection/HeroSection4"
import Services2 from "../../Components/servicesSection/CompanyGroup"

const CompaniesPage= () => {
    return (
        <>
            <HeroSection4 name={"Our Group of Companies"} name2={"Home"} />
            <div class="page-content">
                {/* <AboutSection3 /> */}
                <Services2 />
                {/* <Information1 />
                <BannerAdd addName={"Creative Agency"} addName2={"Business Agency"} addName3={"Design Agency"} addName4={"Marketing Agency "} />
                <Services1 />
                <BannerAdd2 name={"Testimonial"} name2={"Client Feedback"} name3={"Awesome Review"} />\
                <Carousel2 />
                <Counter />
                <BlogSection /> */}
            </div>
        </>
    )
}

export default CompaniesPage
