import img1 from "../../assets/images/products/01.png";
import img2 from "../../assets/images/products/02.jpeg";
import img3 from "../../assets/images/products/03.avif";
import img4 from "../../assets/images/products/01.png";
import img5 from "../../assets/images/products/05.png";
import img6 from "../../assets/images/products/06.png";
import img7 from "../../assets/images/products/07.png";
import img8 from "../../assets/images/products/8.webp";

export const HowWeWorks = [
  {
    index: 1,
    name: "Dewatering Pump",
    description: "Ground water control is very important in many construction and mining sites. We at Logic Fusion LLC understand this and custom build each dewatering pump to suit your need.",
    
    specifications: [
      "Pumping capacity 350 cm3/hour",
      " Pump Suction and discharge (6'x6')",
      "Total Head : 25 mtrs",
      "Vacuum lift : 8.5 mtrs",
      "Volute Housing Cast Iron / SS",
      "  Impeller : Stainless Steel",
      "Stuffing Box : Stainless Steel",
      "Shaft : Stainless Steel",
      "Priming Tank, Priming Tank filter Assembly",
      " Vauum Motor Heavy Duty 70 CFM with Original Parts"
    ],
    engine: [
      "Deutz (Germany) Diesel Type: FL 912,",
      "3 Cylinder, Air Cooled, 34 Kw at 2000 rpm,", "with Starter Motor, Charging Alternator,",
      "Oil Bath Air Filter, Fuel Injection Pump,",
      " Oil Filter Engine mounted Exhaust Silencer,",
      "Manual Shut Off, wire Harness and Starting Panels"
    ]
  },
  {
    index: 2,
    name: "Rubber Suction Hose",
    description: "We are engaged in providing a wide range of Rubber Duct Hose to our valuable client. Manufactured from superior quality rubber, the offered range of duct hoses is majorly used to pass compressed air among or to the pneumatic actuators, tolls, valves and components. We make sure that the provided duct hoses are made in compliance with the industrial guidelines.",
    list: [
      "Flexible Design: Easily bends to fit your needs.",
      "High Durability: Resistant to wear and tear.",
      "Versatile Use: Suitable for various fluids and environments."
    ],
    image: img2
  },
  {
    index: 3,
    name: "Reinforced Clear Hose",
    description: "Clear Hose Pipe offers far higher pressure capability than-reinforced PVC tubing.It is made from non-toxic ingredients conforming to USP class VI and FDA standard.Mirror smooth interior permits outstanding flow characteristics conforming and abrasion resistance with flexibility.",
    list: [
      "Clear Construction: Allows for easy flow monitoring.",
      "Reinforced Strength: Ensures durability and longevity.",
      "Multi-purpose Use: Suitable for various industries."
    ],
    image: img3
  },
  {
    index: 4,
    name: "Brick Saw",
    description: "The Brick Saw is designed for precise cutting of bricks and masonry, ensuring clean, accurate results for your construction projects.",
    list: [
      "Precision Cutting: Achieve clean and accurate cuts with ease.",
      "Durable Blade: Long-lasting performance with high-quality materials.",
      "User-Friendly Design: Easy to operate, ensuring efficiency on the job."
    ],
    image: img4
  },
  {
    index: 5,
    name: "Concrete Mixer",
    description: "The Concrete Mixer is essential for mixing concrete, providing a consistent and reliable mix for construction projects.",
    list: [
      "Efficient Mixing: Ensures a uniform concrete mix.",
      "Robust Design: Built to withstand heavy-duty use.",
      "Easy to Use: Simple controls for efficient operation."
    ],
    image: img5
  },
  {
    index: 6,
    name: "Dewatering Piston Pump",
    description: "We are engaged in offering a quality assured Piston Type Dewatering Pumps, which are known for their heavy duty construction along with lightweight portability. we use advanced technology in the manufactured process.",
    list: [
      "High Pressure: Capable of handling tough dewatering tasks.",
      "Reliable Performance: Consistent operation in harsh conditions.",
      "Compact Design: Easy to transport and install."
    ],
    image: img6
  },
  {
    index: 7,
    name: "Dumpers",
    description: "Dumpers are versatile machines used for transporting bulk materials on construction sites, enhancing efficiency and safety.",
    list: [
      "Heavy Load Capacity: Can handle large amounts of material.",
      "Maneuverable: Easy to navigate on construction sites.",
      "Sturdy Construction: Built for rugged use."
    ],
    image: img7
  },
  {
    index: 8,
    name: "Layflat Hose",
    description: "Our company has achieved laurels in offering  Lay Flat Water Delivery Hoses to the clients. Lay Flat Water Delivery Hoses are properly tested by quality analyzers on various stage of quality to make sure flawlessness and defect free delivery. These products can be avail in various specifications. ",
    list: [
      "Highly flexible, flat roll and easy to take, handle and store",
      "Good elasticity and good cold and hot resistance",
      " Attractive appearance, smooth, bright color, most ideal liquid-transferred high-pressure hose."
    ],
    image: img8
  }
];
