import React from 'react'
import Information1APi from '../../api/Information1APi'

const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '500px', 
};

const featuredItemStyle = {
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '10px',
    textAlign: 'center',
    height: '100%', 
};

const Information1 = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row justify-content-center text-center mb-2">
                        <div className="col-lg-8 col-md-12">
                            <div className="theme-title">
                                <h6>OUR WORKING PROCESS </h6>
                                <h2 className="mb-4">How We Work</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {Information1APi.map((item, index) => {
                            return (
                                <div key={index} className={`col-lg-3 col-md-6${index > 1 ? ' mt-5 mb-5 mt-lg-0' : ''}`} style={cardStyle}>
                                    <div className="featured-item style-2" style={featuredItemStyle}>
                                        <div className="featured-icon">
                                            <i className={item.icon}></i>
                                            <span className="feature-number">0{item.id}</span>
                                        </div>
                                        <div className="featured-desc">
                                            <div className="featured-title">
                                                <h5>{item.title}</h5>
                                            </div>
                                            <p>{item.details}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Information1;
