import React from "react";
import OdometerCount from "../common/Odometer";
import counterApi from "../../api/CounterApi";


const Counter = ({ flex, flex2, background }) => {
  return (
    <section style={{ paddingTop: "40px",paddingBottom:"40px" }}>
      <div className="container">
        <div className={`row ${background} justify-content-center`}>
          {counterApi.map((item, index) => (
            <div className={`col-lg-3 col-md-6 mt-lg-0 ${flex} d-flex justify-content-center`} key={index}>
              <div style={{ borderRadius: "10px", padding: "20px", margin: "10px", width: "100%", maxWidth: "300px" }}>
                <div className="counter style-1">
                  <div className={`counter-top ${flex2} d-flex justify-content-center align-items-center`}>
                    <div className="counter-icon">
                      <i className={item.icon} />
                    </div>
                    <div className="counter-desc d-flex align-items-center ml-3">
                      <span className="count-number">
                        <OdometerCount countUp={item.count} />
                      </span>
                      <span>k</span>
                    </div>
                  </div>
                  <h6 className="text-center mt-3">{item.label}</h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Counter;