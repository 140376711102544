import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import CarouselApi from "../../api/CarouselApi/CarouselApi";
import { NavLink, useNavigate } from "react-router-dom";
import AgencySvg from "../svg/Agency";
import axios from "axios";
import API_BASE_URL from "../../api/API/baseUrl";
import API_IMAGE_URL from "../../api/API/baseImageUrl";


const Carousel = ({ margin }) => {
  const navigate = useNavigate();
  const [productItems, setProductItems] = useState([]);
  const [categoryId, setCategoryId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryResponse = await axios.get(`${API_BASE_URL}/gallery`);

        const productsData = galleryResponse.data.data.find(category => category.name === "Products");
        const categoryId = productsData ? productsData.id : null;
        setCategoryId(categoryId);

        const items = productsData ? productsData.subcategories.flatMap(subcategory =>
          subcategory.items.map(item => ({
            ...item,
            subcategoryId: subcategory.id,
            subcategoryName: subcategory.name
          }))
        ) : [];
        setProductItems(items);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, []);

  const portfolio = (item, index) => {
    console.log("🚀 ~ file: Carousel.js:9 ~ portfolio ~ item:", item);
    localStorage.setItem('portfolio', JSON.stringify(item));
    navigate(`/products/${index + 1}`);
  };

  const options = {
    items: 1,
    nav: false,
    dots: false,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    lazyLoad: true,
    lazyContent: true,
    center: true,
    smartSpeed: 450,
  };

  return (
    <div style={{ marginBottom: "50px", marginTop: "25px" }}>
      <OwlCarousel
        id="custom-customer-testimonoals"
        {...options}
        responsive={{
          0: {
            items: 1, // Show 1 item for screens with width less than or equal to 0px (mobile screens)
          },
          768: {
            items: 2, // Show 3 items for screens with width greater than or equal to 768px (larger screens)
          },
          1000: {
            items: 3, // Show 3 items for screens with width greater than or equal to 768px (larger screens)
          },
        }}
      >
        {productItems?.map((item, index) => {
          return (
            <section style={{ paddingTop: "50px", paddingBottom: "50px" }} key={index} className={`pt-0 item mt ${margin}`}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="no-pb portfolio-item style-1 ">
                      <div id="portfolio-desc" className="item"></div>
                      <div id="portfolio-desc_container" className=" item">
                        <div className="portfolio-fild">{item.title}</div>
                        <h4>
                          <NavLink to="/products" className="portfolio-position" style={{ fontSize: "16px" }}>
                            {item.subcategoryName}
                          </NavLink>
                        </h4>
                      </div>
                      {item.images.length > 0 && (
                        <img style={{ height: "350px" }} className="portfolio-border-radius" src={API_IMAGE_URL + item.images[0].image} alt={item.title} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
        })}
      </OwlCarousel>

      {/* <section className="about-section pt-0" style={{ paddingBottom: "10px", paddingTop: "50px" }}>
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-12 col-md-12">
              <div className="theme-title mt-5 text-left">
                <h3>Expanding Horizons with Quality Dewatering Solutions</h3>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="theme-title">
                <p>
                  We specialize in top-notch dewatering solutions, offering high-performance submersible, centrifugal, and piston pumps for efficient water extraction, drainage, and sewage management across various industries.
                </p>
                <p>
                  Our global reach ensures the export and import of premium dewatering pumps and accessories, maintaining the highest standards of quality and reliability with streamlined international trade operations.
                </p>
                <p>
                  We offer custom manufacturing services, working closely with clients to design and produce dewatering equipment tailored to specific project needs for optimal performance.
                </p>
                <p>
                  Our comprehensive repair and maintenance services ensure the longevity and efficiency of our equipment, while our rental services provide flexible access to top-tier dewatering equipment for temporary needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Carousel;