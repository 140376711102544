import AboutApi3 from "../../api/AboutApi/AboutApi3";

const AboutSection4 = () => {
    return (
        <section className="pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="theme-title">
                            <h6>Why Choose Our Dewatering Products?</h6>
                            <h2>Comprehensive Solutions Tailored to Your Needs</h2>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 ps-lg-8 mt-5 mt-lg-0">
                        <div className="row">
                            {AboutApi3.map((feature) => (
                                <div className={`col-md-6 ${feature.id % 2 === 0 ? "mt-5" : ""}`} key={feature.id} style={{padding:"5px"}}>
                                    <div className="featured-item style-3">
                                        <div className="featured-icon">
                                            <i className={`flaticon ${feature.icon}`} aria-hidden="true"></i>
                                        </div>
                                        <div className="featured-description">
                                            <div className="featured-title">
                                                <h5>{feature.title}</h5>
                                            </div>
                                            <p>{feature.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection4;