const AboutApi2 = [
  {
    icon: "flaticon flaticon-statistics",
    title: "Specialized Global Logistics",
    description:
      "We lead in exporting and importing dewatering pumps and accessories, offering efficient water management solutions globally.",
  },
  {
    icon: "flaticon flaticon-idea",
    title: "Mastery in Manufacturing",
    description:
      "Innovative spare parts manufacturing for top dewatering pump brands, ensuring reliability and durability for your operations.",
  },
  {
    icon: "flaticon flaticon-rocket-launch",
    title: "Expert Hydraulic Systems",
    description:
      "Experts in hydraulic hoses, fittings, and assembly, providing tailored fluid conveyance solutions for industrial needs.",
  },
  {
    icon: "flaticon flaticon-announcement",
    title: "24/7 Client Care",
    description:
      "Dedicated round-the-clock support for all dewatering pump and equipment needs, ensuring uninterrupted operations.",
  },
  {
    icon: "flaticon flaticon-rating",
    title: "Commitment to Excellence",
    description:
      "Adherence to stringent quality standards guarantees exceptional products and services, enhancing customer satisfaction and operational efficiency.",
  },
  {
    icon: "flaticon flaticon-delivery",
    title: "Global Expansion",
    description:
      "An extensive global network ensures timely delivery and support worldwide for our comprehensive range of products and services.",
  },
];

export default AboutApi2;
