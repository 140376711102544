import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectApi from "../../api/ProjectApi/ProjectApi";
import { NavLink } from "react-router-dom";

const Project1 = ({ margin }) => {
    const [hoveredServiceIndex, setHoveredServiceIndex] = useState(4);
    const navigate = useNavigate()
    const portfolio = (link) => {
       
        navigate(`${link}`)
    }
    return (
        <section className={`pt-0 ${margin}`}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="portfolio-list">
                            {ProjectApi.map((project, index) => (
                                <div key={index} className={`portfolio-item style-2 ${hoveredServiceIndex === index ? "active" : ""} `}>
                                    <div className="portfolio-image service-img">
                                        {
                                            hoveredServiceIndex === index ?
                                                <img style={{ height: "500px" }} src={hoveredServiceIndex === index ? project.imageSrc : ""} class="img-fluid " alt="" />
                                                : ""
                                        }
                                    </div>

                                    <div className="portfolio-desc" onMouseEnter={() => setHoveredServiceIndex(index)}>
                                        <div className="portfolio-cat" >
                                            <NavLink className="text-decoration-none text-reset" rel="tag">
                                                {project.title}
                                            </NavLink>
                                        </div>
                                        
                                        <h4 onClick={() => { portfolio(project.titleLink) }}>
                                            <NavLink className="text-decoration-none " >{project.subTitle}</NavLink>
                                        </h4>
                                    
                                    </div>
                                  
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Project1;
