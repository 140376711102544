import React, { useEffect, useState } from 'react'
import CommentsApi from '../../api/CommentsApi '
import BlogLeftApi from '../../api/BlogApi/BlogLeftApi'
import { NavLink, useParams } from 'react-router-dom'
import Sidebar from '../../Components/sideBar/Sidebar'
import ServicesApi2 from '../../api/ServicesApi/ServicesApi2'
import axios from 'axios'
import API_BASE_URL from '../../api/API/baseUrl'
import API_IMAGE_URL from '../../api/API/baseImageUrl'
import parse from 'html-react-parser';



const ServiceSinglePage = () => {
    const { id } = useParams();
    const service = ServicesApi2.find(item => item.id === parseInt(id));
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const serviceResponse = await axios.get(`${API_BASE_URL}/service?${id}`);
                setData(serviceResponse.data.data);
                console.log("Fetched service data:", serviceResponse.data.data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);


    if (!data) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
                padding: '20px',
            }}>
                <div style={{
                    textAlign: 'center',
                    backgroundColor: 'black',
                    color: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                    fontSize: "24px"
                }}>
                    Service Details not found
                </div>
            </div>
        );
    }


    const renderImages = (headingNumber) => {
        if (!data) return null;

        const images = [];
        for (let i = 2; data[`img${i}`]; i++) {
            const imgHeadingNumber = data[`img${i}`].match(/(\d+)/)[0];
            if (imgHeadingNumber === headingNumber) {
                images.push(
                    <div className="col-lg-4 mb-3" key={i}>
                        <img
                            className="img-fluid"
                            src={API_IMAGE_URL + data[`img${i}`]}
                            alt={`Image ${i}`}
                            style={{ marginBottom: '10px' }}
                        />
                    </div>
                );
            }
        }
        return images;
    };

    return (
        <section style={{ padding: '20px 0' }}>
            <div className="container">
                <div className="row">
                    <div className="theme-title">
                        <h2>{data?.name}</h2>
                    </div>
                </div>
                <div className="row mt-5 mb-5">
                    <div className="col-lg-6 col-12 border-start ps-5 border-light d-flex align-items-center">
                        <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'hidden', margin: 'auto' }}>
                            {data && data.img1 && (
                                <img className="img-fluid" src={API_IMAGE_URL + data.img1} alt="Service Image" style={{ height: "50vh", width: "100%" }} />
                            )}
                            {/* {data.img1 && <img src={data.img1} alt={data.name} className="mr-4" style={{ maxWidth: '100%', height: 'auto' }} />} */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 border-start ps-5 border-light">
                        <div className='mt-5'>
                            {data && data.description.split('\n').map((para, index) => (
                                <p className='justify-text' key={index}>{parse(para)}</p>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-12">
                        <div className="mt-5">
                            {data && Object.keys(data).map((key) => {
                                if (key.startsWith("h")) {
                                    const headingNumber = key.slice(1);
                                    return (
                                        <div key={key} style={{ marginBottom: '20px' }}>
                                            <h3 className='mt-5 mb-2'>{data[key]}</h3>
                                            {data[`desc${headingNumber}`] && (
                                                <p className='justify-text'>{parse(data[`desc${headingNumber}`])}</p>
                                            )}
                                            <div className="row">
                                                {renderImages(headingNumber)}
                                            </div>
                                            {data[`desc${headingNumber}_2`] && (
                                                <p className='justify-text'>{parse(data[`desc${headingNumber}_2`])}</p>
                                            )}
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceSinglePage;

