import React, { useEffect, useState } from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import Project1 from '../../Components/projectSection/Project1'
import Project3 from '../../Components/projectSection/Project3'
import axios from 'axios'
import API_BASE_URL from '../../api/API/baseUrl'

const Portfolio_style_three = () => {
    const [data, setData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryResponse = await axios.get(`${API_BASE_URL}/gallery`);
                setData(galleryResponse.data.data);
                console.log(galleryResponse?.data?.data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <>
            <HeroSection4 name={"Gallery"} name2={"Home"} />
            <div className="page-content">
            <Project3 data={data} margin={"mt-10"} />
            </div>
        </>
    )
}

export default Portfolio_style_three
