import React from 'react';
import { Link } from 'react-router-dom';
import ServicesApi2 from '../../api/ServicesApi/ServicesApi2';
import API_IMAGE_URL from '../../api/API/baseImageUrl';

// const ServiceItem = ({ title, link, description, images, isReverse }) => (
//   <div className="container">
//     <div
// className={`row align-items-center featured-item style-3 ${!isReverse ? 'reverse' : ''} ${isReverse ? 'flex-row-reverse' : 'flex-row'}`}
// style={{ marginBottom: '5rem', transition: 'transform 0.3s ease, box-shadow 0.3s ease' }}
// onMouseEnter={(e) => { e.currentTarget.style.transform = 'translateY(-10px)'; e.currentTarget.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.1)'; }}
// onMouseLeave={(e) => { e.currentTarget.style.transform = 'translateY(0)'; e.currentTarget.style.boxShadow = 'none'; }}
//     >
//       <div className="col-md-12 col-lg-6">
//         <div className="service-desc">
//           <h4>
//             <Link to={link} className="member-name text-decoration-none">{title}</Link>
//           </h4>
//           {description.split('\n').map((para, index) => (
//             <p key={index}>{para}</p>
//           ))}          {/* <ul className="list-unstyled list-icon" style={{ marginBottom: '1rem' }}>
//             {list.map((item, index) => (
//               <li style={{ marginBottom: '1rem' }} key={index}>
//                 <i className="las la-check-circle" style={{ marginRight: "1rem" }} />
//                 {item}
//               </li>
//             ))}
//           </ul> */}
//           <Link to={link} className="rounded-button text-decoration-none" style={{ marginBottom: '3rem' }}>
//             <i className="flaticon-forward"></i>
//           </Link>
//         </div>
//       </div>
//       <div className="col-md-12 col-lg-6">
//         <img src={API_IMAGE_URL+`${images}`} alt={title} className="img-fluid" style={{ width: '100%', borderRadius: '10px' }} />
//       </div>
//     </div>
//   </div>
// );

// const Services3 = ({ serviceData }) => {

//   return (
//     <section className="pt-0 mt-5">
//       <div className="container">
//         <div className="row justify-content-center text-center mb-5">
//           <div className="col-lg-8 col-md-12">
//             <div className="theme-title">
//               <h6>Leading Experts in Dewatering Pumps & Spares</h6>
//               <h2>Providing Innovative Solutions and Exceptional Service</h2>
//             </div>
//           </div>
//         </div>
//         {serviceData?.map((service, index) => (
//           <ServiceItem
//             key={service.id}
//             title={service.name}
//             link={`/service/${service.id}`}
//             description={service.description}
//             // list={service.list}
//             images={service.img1}
//             isReverse={index % 2 !== 0}
//           />
//         ))}
//       </div>
//     </section>
//   );
// };

// export default Services3;

const ServiceItem = ({ title, link, images, description, isReverse }) => (
  <Link to={link} className="text-decoration-none">
    <div className="container">
      <div
        className={`row align-items-stretch featured-item style-3 ${!isReverse ? 'reverse' : ''} ${!isReverse ? 'flex-row-reverse' : 'flex-row'}`}
        style={{ height: '350px', marginBottom: '5rem', transition: 'transform 0.3s ease, box-shadow 0.3s ease' }}
        onMouseEnter={(e) => { e.currentTarget.style.transform = 'translateY(-10px)'; e.currentTarget.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.1)'; }}
        onMouseLeave={(e) => { e.currentTarget.style.transform = 'translateY(0)'; e.currentTarget.style.boxShadow = 'none'; }}
      >
        <div className="col-md-12 col-lg-6">
          <img src={API_IMAGE_URL + `${images}`} alt={title} className="img-fluid" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }} />
        </div>
        <div className="col-md-12 col-lg-6 d-flex align-items-center">
          <div className="service-desc">
            <h4>
              <Link to={link} className="member-name text-decoration-none">{title}</Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </Link>
);

const Services3 = ({ serviceData }) => {
  if (!serviceData) {
    return (
      <>
        <section>
          <div className="row justify-content-center text-center mb-5">
            <div className="col-lg-8 col-md-12">
              <div className="theme-title">
                <h6>Leading Experts in Dewatering Pumps & Spares</h6>
                <h2>Providing Innovative Solutions and Exceptional Service</h2>
              </div>
            </div>
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            padding: '20px',
          }}>
            <div style={{
              textAlign: 'center',
              backgroundColor: 'black',
              color: 'white',
              padding: '20px',
              borderRadius: '10px',
              fontSize: "24px"
            }}>
              Services not found
            </div>
          </div>
        </section>
      </>);
  }
  return (
    <section className="pt-0 mt-5">
      <div className="container">
        <div className="row justify-content-center text-center mb-5">
          <div className="col-lg-8 col-md-12">
            <div className="theme-title">
              <h6>Leading Experts in Dewatering Pumps & Spares</h6>
              <h2>Providing Innovative Solutions and Exceptional Service</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {serviceData?.map((service, index) => (
            <div className="col-md-6 mb-2" key={service.id}>
              <ServiceItem
                title={service.name}
                link={`/service/${service.id}`}
                images={service.img1}
                isReverse={index % 2 !== 0}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services3;




