import React, { useEffect, useRef, useState } from "react";
import AboutApi1 from "../../api/AboutApi1";
import AgencySvg from "../svg/Agency";
import { NavLink } from "react-router-dom";
import OurMissionApi from "../../api/OurMissionApi";
import img from "../../assets/images/about/goals_1.jpg"

const placeholderImageUrl = img


const Goals = ({ img, imgStyle }) => {
    const [inView, setInView] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setInView(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    const fadeInStyle = {
        opacity: inView ? 1 : 0,
        transition: 'opacity 1s ease-in-out',
    };

    const goalsData = [
        { title: 'Achieve Sustainability', description: 'Implementing sustainable practices for long-term impact.', icon: 'las la-tree' },
        { title: 'Expand Market Reach', description: 'Focused on strategic partnerships for growth.', icon: 'las la-globe' },
        { title: 'Innovate Technology', description: 'Investing in cutting-edge technology for industry leadership.', icon: 'las la-cogs' }
    ];

    return (
        <section className="about-section pt-0" style={{ paddingBottom: "25px" }} ref={sectionRef}>
            <div className="container">
                <div className="row align-items-center" style={fadeInStyle}>
                    <div className="col-12 col-lg-6 position-relative">
                        <img
                            className={`img-fluid rounded-bottom${imgStyle}`}
                            src={placeholderImageUrl}
                            style={{ borderRadius: "25px", height: "450px", width: "100%", objectFit: "cover", padding: "15px" }}
                            alt="Placeholder Image"
                            onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                            onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                        />
                    </div>
                    <div className="col-12 col-lg-6 ps-lg-5 mt-5 mt-lg-0 theme-title">
                        <p style={{ textAlign: "justify", marginTop: "15px" }}>
                            Our commitment at Logic Fusion LLC is to redefine industry standards through innovation and excellence. We strive to lead by example in delivering cutting-edge solutions that positively impact our clients and communities.
                        </p>
                        <ul style={{ textAlign: "left", marginTop: "15px", paddingLeft: "0", listStyleType: "none" }}>
                            {goalsData.map((goal, index) => (
                                <li key={index} style={{ marginBottom: "10px" }}>
                                    <i className="la la-check-circle" style={{ color: "#ff6a00", marginRight: "10px" }}></i>
                                    {goal.description}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Goals;