import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import Portfolio from '../../Components/portfolioSection/Portfolio'

const portfolio_single = () => {
  return (
    <>
    
      <div class="page-content">
        <Portfolio />
      </div>

    </>
  )
}

export default portfolio_single
