import React from 'react'
import large6 from '../../assets/images/services/large/06.jpg'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import BlogSection2 from '../../Components/blogSection/BlogSection2'

const OurProducts= () => {
  return (
    <>
      <HeroSection4 name={"Our Products"} name2={"Home"}/>
      <BlogSection2/>
    </>
  )
}

export default OurProducts