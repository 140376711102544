import React, { useState } from "react";
import ServicesApi1 from "../../api/ServicesApi/ServicesApi1";
import { NavLink } from "react-router-dom";
import Carousel5 from "../reactcarouselSection/Carousel5";
import ServicesApi2 from "../../api/ServicesApi/ServicesApi2";

const Services1 = () => {
  const [hoveredServiceIndex, setHoveredServiceIndex] = useState(0);
  return (
    <>
      <section style={{paddingBottom:"25px"}}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="service-list">
                <div className="theme-title ">
                  <h6>OUR SPECIALIZED SERVICES </h6>
                </div>
                {ServicesApi1.map((item, index) => (
                  <div
                    key={index}
                    className={`service-item style-1 ${hoveredServiceIndex === index ? "active" : ""
                      }`}
                  >
                    <div className="service-image">
                      {hoveredServiceIndex === index ? (
                        <img style={{ height: "825px", marginTop: "15px" }}
                          src={hoveredServiceIndex === index ? item.img : ""}
                          className="img-fluid service-img"
                          alt={`${item.id}`}
                          decoding="async"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="service-desc"
                      onMouseEnter={() => setHoveredServiceIndex(index)}
                    >
                      <h4>
                        <NavLink to={item.path} className="service-title">
                          {item.title}
                        </NavLink>
                      </h4>
                      <p style={{ fontSize: "14px" }}>{item.description}</p>
                      <NavLink to={item.path} className="rounded-button cursor-pointer servie-pointer">
                        <i className="flaticon-forward" />
                      </NavLink>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ paddingTop: '5px' }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="theme-title ">
                <h3 className="text-center mb-4">A Glimpse of Our Services</h3>
              </div>
              <Carousel5 />
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Services1;
