import React, { useState } from "react";
import ProgressBar from "../common/ProgressBar";
import { NavLink, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import CompaniesAPI from "../../api/CompaniesApi/CompaniesApi";



const CompanyDetailsTwo = () => {
    return (
        <section style={{ paddingTop: "25px", paddingBottom: "15px" }}>
            <div className="container">
                <div className="row">
                    {CompaniesAPI.map((company) => (
                        <CompanyDetailTwo key={company.id} company={company} />
                    ))}
                </div>
            </div>
        </section>
    );
};

const CompanyDetailTwo = ({ company }) => {
    const [isHovered, setIsHovered] = useState(false);

    const cardStyle = {
        width: '100%',
        position: 'relative',
        backgroundColor: '#333',
        color: '#fff',
        transformStyle: 'preserve-3d',
        cursor: 'pointer',
        border: '1px solid #444',
        borderRadius: '8px',
        overflow: 'hidden',
    };

    const cardFrontStyle = {
        height: '100%',
        width: '100%',
        backfaceVisibility: 'hidden',
        transform: isHovered ? 'rotateY(-180deg)' : 'rotateY(0)',
        transition: 'transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    };

    const cardBackStyle = {
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        backfaceVisibility: 'hidden',
        transform: isHovered ? 'rotateY(0)' : 'rotateY(180deg)',
        backgroundColor: '#222',
        color: '#fff',
        padding: '5px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
        transition: 'transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (

        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="card h-100" style={cardStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <div className="card-front" style={cardFrontStyle}>
                    <img className="card-img-top" src={company.imageSrc} alt={company.name} style={{ width: '100%', height: '100%', objectFit: 'contain',backgroundColor:"black" }} />
                </div>
                <div className="card-body" style={cardBackStyle}>
                    <h5 className="card-title" style={{ marginBottom: '5px', padding: '5px', borderRadius: '5px', background: 'linear-gradient(135deg, var(--theme-primary-color), var(--theme-secondary-color) 87%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                        <a href={company.website} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>{company.name}</a>
                    </h5>
                    <ul className="list-unstyled" style={{ marginTop: '5px', paddingLeft: '0' }}>
                        <li className="d-flex align-items-center" style={{ marginBottom: '10px' }}>
                            <i className="flaticon-phone-call me-2" style={{ color: 'var(--theme-primary-color)' }} />
                            <div>
                                <span>Phone:</span>
                                <NavLink to={`tel:${company.phone}`} style={{ marginLeft: '10px', color: 'inherit', textDecoration: 'none' }}>{company.phone}</NavLink>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mt-1" style={{ marginBottom: '10px' }}>
                            <i className="flaticon-chat me-2" style={{ color: 'var(--theme-primary-color)' }} />
                            <div>
                                <span>Email:</span>
                                <NavLink to={`mailto:${company.email}`} style={{ marginLeft: '10px' }}>{company.email}</NavLink>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mt-1">
                            <i className="la la-chrome me-2" style={{ color: 'var(--theme-primary-color)' }} />
                            <div>
                                <span>Website:</span>
                                <NavLink to={company.website} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px', color: 'inherit', textDecoration: 'none' }}>{company.website}</NavLink>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    );
};

export default CompanyDetailsTwo;
