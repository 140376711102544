import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProjectApi4 from '../../api/ProjectApi/ProjectApi4';
import axios from 'axios';
import API_BASE_URL from '../../api/API/baseUrl';
import API_IMAGE_URL from '../../api/API/baseImageUrl';


const Portfolio3 = () => {
    const { categoryId, subCatId, id } = useParams();
    const [data, setData] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [foundItem, setFoundItem] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryResponse = await axios.get(`${API_BASE_URL}/gallery`);
                setData(galleryResponse.data.data);

                const foundCategory = galleryResponse.data.data.find(category =>
                    category.id === parseInt(categoryId)
                );

                let foundItem = null;

                if (foundCategory) {
                    if (subCatId) {
                        const foundSubcategory = foundCategory.subcategories.find(subcategory =>
                            subcategory.id === parseInt(subCatId)
                        );

                        if (foundSubcategory) {
                            foundItem = foundSubcategory.items.find(item =>
                                item.id === parseInt(id)
                            );
                        }
                    } else {
                        foundItem = foundCategory.items.find(item =>
                            item.id === parseInt(id)
                        );
                    }
                }

                console.log("Found item:", foundItem);
                setFoundItem(foundItem);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [categoryId, subCatId, id]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (selectedImageIndex !== null) {
                if (event.key === 'ArrowRight') {
                    showNextImage();
                } else if (event.key === 'ArrowLeft') {
                    showPrevImage();
                } else if (event.key === 'Escape') {
                    closeImage();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedImageIndex]);

    const openImage = (index) => {
        setSelectedImageIndex(index);
    };

    const closeImage = () => {
        setSelectedImageIndex(null);
    };

    const showNextImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % foundItem.images.length);
    };

    const showPrevImage = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + foundItem.images.length) % foundItem.images.length);
    };

    if (!foundItem) {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
            }}>
                <div style={{
                    textAlign: "center",
                    padding: "20px",
                    margin: "auto",
                    maxWidth: "80%",
                    fontSize: "32px"
                }}>
                    No Images Available
                </div>
            </div>
        );
    }

    const itemTitle = foundItem.title;

    return (
        <>
            <div className="container">
                <div className="row justify-content-center mt-4 mb-3">
                    <h1 style={{ textAlign: "center", margin: "20px 0" }}>{itemTitle}</h1>
                </div>
                <div className="row">
                    {foundItem.images.map((image, index) => (
                        <div key={image.id} className="col-lg-4 col-md-6" style={{ cursor: "pointer" }} onClick={() => openImage(index)}>
                            <section className="pt-0 item mt-4 margin">
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="no-pb portfolio-item style-1">
                                                <div id="portfolio-desc" className="item"></div>
                                                <img style={{ height: "400px", borderRadius: "10px", objectFit: "cover", width: "100%" }} className="portfolio-border-radius img-fluid" src={API_IMAGE_URL + image.image} alt={`Gallery Image`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    ))}
                </div>
            </div>
            {selectedImageIndex !== null && (
                <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.7)", zIndex: 999, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button className="primary-btn text-decoration-none" style={{ padding: "15px", position: "absolute", top: "20px", right: "20px", zIndex: 1000, fontSize: "25px", cursor: "pointer" }} onClick={closeImage}>✖</button>
                    <button className='primary-btn text-decoration-none' onClick={showPrevImage} style={{ padding: "10px", position: "absolute", top: "50%", left: "20px", transform: "translateY(-50%)", zIndex: 1000, fontSize: "30px", cursor: "pointer" }}>❮</button>
                    <button className='primary-btn text-decoration-none' onClick={showNextImage} style={{ padding: "10px", position: "absolute", top: "50%", right: "20px", transform: "translateY(-50%)", zIndex: 1000, fontSize: "30px", cursor: "pointer" }}>❯</button>
                    <div style={{ padding: "20px", backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", maxWidth: "90%", maxHeight: "90%" }}>
                        <img style={{ maxWidth: "100%", maxHeight: "80vh", display: "block", margin: "0 auto" }} src={API_IMAGE_URL + foundItem.images[selectedImageIndex].image} alt={`Gallery Image`} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Portfolio3;
