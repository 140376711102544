import React from 'react'
import Carousel3 from '../reactcarouselSection/Carousel3';
import CompanyDetails from '../teamDetails/companyDetails';
import CompanyDetailsTwo from '../teamDetails/CompanyDetailsTwo';

const CompanyGroup = () => {
    return (
        <section className="pt-5" style={{paddingBottom:"25px"}}>
            <div className="container">
                <div className="row justify-content-center text-center mb-5">
                    <div className="col-lg-12 col-md-12">
                        <div className="theme-title">
                            <h6>Our Diverse Ventures</h6>
                            <h2>Explore our varied ventures and subsidiaries.</h2>
                            <p style={{ textAlign: "justify" }}>
                                As a conglomerate specializing in dewatering and related products, our company operates a diverse portfolio of ventures and subsidiaries across different sectors. From innovative dewatering solutions to environmental management services, we offer a comprehensive range of products and services to meet the needs of various industries.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <CompanyDetailsTwo />
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-lg-12 col-md-12 mb-4">
                        <h3>Key Achievements</h3>
                        <ul className="list-unstyled">
                            <li>Company X received the "Best Innovation Award" in 2023 for its groundbreaking dewatering technology.</li>
                            <li>Company Y achieved a revenue growth of 25% in the last fiscal year, driven by its successful expansion into new markets.</li>
                        </ul>
                    </div> */}
                    {/* <div className="col-lg-12 col-md-12 mb-4">
                        <h3>Testimonials</h3>
                        <blockquote className="blockquote">
                            <p className="mb-0">"Company Z has provided exceptional services, exceeding our expectations. Highly recommended!"</p>
                            <footer className="blockquote-footer mt-2">John Doe, CEO of ABC Corporation</footer>
                        </blockquote>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default CompanyGroup;
