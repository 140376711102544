const AboutApi1 = [
{
    details:"Expertise in Dewatering Pump Export & Import"
},
{
    details:"Manufacturing of Pump Spare Parts"
},
{
    details:"Hydraulic Hoses & Fittings Expertise"
},
{
    details:"Rental & Repair Services"
},
{
    details:"Fabrication & Foundry Capabilities"
},
{
    details:"Heavy Equipment Hiring"
}
]
export default AboutApi1
