const ProjectApi4 = [
    {
        categoryId: 1,
        category: "Projects",
        items: [
            {
                id: 1,
                title: "Coastal Dewatering Project",
                images: [
                    { id: 1, imageUrl: 'https://via.placeholder.com/600' },
                    { id: 2, imageUrl: 'https://www.mbexports.com/uploads/gallery/Xjf9TbLQFg.JPG' },
                    { id: 3, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 4, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 5, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 6, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 7, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 8, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 9, imageUrl: 'https://via.placeholder.com/300' }
                ]
            },
            {
                id: 2,
                title: "Urban Flood Control System",
                images: [
                    { id: 1, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 2, imageUrl: 'https://www.mbexports.com/uploads/gallery/Xjf9TbLQFg.JPG' },
                    { id: 3, imageUrl: 'https://via.placeholder.com/300' }
                ]
            },
            {
                id: 3,
                title: "Mountainous Region Pump Setup",
                images: [
                    { id: 1, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 2, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 3, imageUrl: 'https://via.placeholder.com/300' }
                ]
            }
        ]
    },
    {
        categoryId: 2,
        category: "Products",
        subCategories: [
            {
                subCategory: "Dewatering Pumps",
                subCategoryId:1,
                items: [
                    {
                        id: 1,
                        title: "High-Efficiency Dewatering Pump",
                        images: [
                            { id: 1, imageUrl: 'https://www.mbexports.com/uploads/gallery/Xjf9TbLQFg.JPG' },
                            { id: 2, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 3, imageUrl: 'https://via.placeholder.com/400x300' }
                        ]
                    },
                    {
                        id: 2,
                        title: "Portable Dewatering Pump",
                        images: [
                            { id: 1, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 2, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 3, imageUrl: 'https://via.placeholder.com/400x300' }
                        ]
                    },
                    {
                        id: 3,
                        title: "Submersible Dewatering Pump",
                        images: [
                            { id: 1, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 2, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 3, imageUrl: 'https://via.placeholder.com/400x300' }
                        ]
                    }
                ]
            },
            {
                subCategory: "Accessories",
                subCategoryId:2,
                items: [
                    {
                        id: 1,
                        title: "Industrial Layflat Hose",
                        images: [
                            { id: 1, imageUrl: 'https://africanminingmarket.com/wp-content/uploads/2022/12/atlas_copco_pac_ipr.jpg' },
                            { id: 2, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 3, imageUrl: 'https://via.placeholder.com/400x300' }
                        ]
                    },
                    {
                        id: 2,
                        title: "Pump Fittings",
                        images: [
                            { id: 1, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 2, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 3, imageUrl: 'https://via.placeholder.com/400x300' }
                        ]
                    },
                    {
                        id: 3,
                        title: "Control Panels",
                        images: [
                            { id: 1, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 2, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 3, imageUrl: 'https://via.placeholder.com/400x300' }
                        ]
                    }
                ]
            },
            {
                subCategory: "Shafts",
                subCategoryId:3,
                items: [
                    {
                        id: 1,
                        title: "Stainless Steel Shaft",
                        images: [
                            { id: 1, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 2, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 3, imageUrl: 'https://via.placeholder.com/400x300' }
                        ]
                    },
                    {
                        id: 2,
                        title: "High-Strength Alloy Shaft",
                        images: [
                            { id: 1, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 2, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 3, imageUrl: 'https://via.placeholder.com/400x300' }
                        ]
                    },
                    {
                        id: 3,
                        title: "Custom Machined Shaft",
                        images: [
                            { id: 1, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 2, imageUrl: 'https://via.placeholder.com/400x300' },
                            { id: 3, imageUrl: 'https://via.placeholder.com/400x300' }
                        ]
                    }
                ]
            }
        ]
    },
    {
        categoryId: 3,
        category: "Services",
        items: [
            {
                id: 1,
                title: "Pump Repair Services",
                images: [
                    { id: 1, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 2, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 3, imageUrl: 'https://via.placeholder.com/300' }
                ]
            },
            {
                id: 2,
                title: "Custom Fabrication Services",
                images: [
                    { id: 1, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 2, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 3, imageUrl: 'https://via.placeholder.com/300' }
                ]
            },
            {
                id: 3,
                title: "On-Site Maintenance Services",
                images: [
                    { id: 1, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 2, imageUrl: 'https://via.placeholder.com/300' },
                    { id: 3, imageUrl: 'https://via.placeholder.com/300' }
                ]
            }
        ]
    }
];

export default ProjectApi4;
