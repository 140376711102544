import React from 'react';

import { Card, CardBody, CardTitle, CardText, Row, Col } from 'reactstrap';
import OurMission from './OurMission';
import Goals from './Goals';
import Values from './Values';


const MissionValuesGoalsSection = () => {
    const valuesData = [
        { title: 'Ethics', description: 'Committed to upholding the highest ethical standards and fostering a culture of integrity.', icon: 'las la-shield-alt' },
        { title: 'Integrity', description: 'Prioritizing honesty, transparency, and accountability to build strong relationships.', icon: 'las la-lock' },
        { title: 'Quality', description: 'Delivering excellence and innovation while maintaining stringent quality standards.', icon: 'las la-star' }
    ];

    const goalsData = [
        { title: 'Achieve Sustainability', description: 'Implementing sustainable practices for long-term impact.', icon: 'las la-tree' },
        { title: 'Expand Market Reach', description: 'Focused on strategic partnerships for growth.', icon: 'las la-globe' },
        { title: 'Innovate Technology', description: 'Investing in cutting-edge technology for industry leadership.', icon: 'las la-cogs' }
    ];



    return (
        <section style={{ paddingTop: "5px", paddingBottom: "25px" }} className="about-section">
            <div className="container mb-5">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-12 ps-lg-5 mt-5 mt-lg-0">
                        <div className="theme-title text-center">
                            <h6 style={{ marginBottom: "35px" }}>Our Mission, Values, and Goals</h6>
                            <OurMission />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5 mb-5">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-12 ps-lg-5 mt-5 mt-lg-0">
                        <div className="theme-title">
                            <h2>Our Values</h2>
                            <p className='justify-text'>
                                At Logic Fusion LLC, our values define who we are and how we operate. They guide our decisions, actions, and relationships, shaping our corporate culture and driving our success.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {valuesData.map((value, index) => (
                        <div className="col-12 col-md-6 col-lg-4 mt-5" key={index}>
                            <Card className="mb-4 featured-item style-3 text-white" style={{ height: "200px" }}>
                                <CardBody className="text-center d-flex flex-column align-items-center">
                                    {index === 0 && <i className="las la-shield-alt la-2x mb-3" style={{ color: "#ff6a00" }}></i>}
                                    {index === 1 && <i className="las la-lock la-2x mb-3" style={{ color: "#ff6a00" }}></i>}
                                    {index === 2 && <i className="las la-star la-2x mb-3" style={{ color: "#ff6a00" }}></i>}
                                    <h3 className="mb-3">{value.title}</h3>
                                    {/* <CardText>{value.description}</CardText> */}
                                </CardBody>
                            </Card>
                        </div>
                    ))}
                </div>
                <Values />
            </div>
            <div className="container mt-5 mb-5">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-12 ps-lg-5 mt-5 mt-lg-0">
                        <div className="theme-title">
                            <h2>Our Goals</h2>
                            <p className='justify-text'>
                                Our goals at Logic Fusion LLC reflect our commitment to continuous improvement, growth, and innovation. We are dedicated to achieving success in key areas that drive our business forward.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {goalsData.map((goal, index) => (
                        <div className="col-12 col-md-6 col-lg-4 mt-5" key={index}>
                            <Card className="mb-4 featured-item style-3 text-white" style={{ height: "250px" }}>
                                <CardBody className="text-center d-flex flex-column align-items-center">
                                    <i className={`${goal.icon} la-2x mb-3`} style={{ color: "#ff6a00" }}></i>
                                    <h3 className="mb-3">{goal.title}</h3>
                                    {/* <CardText>{goal.description}</CardText> */}
                                </CardBody>
                            </Card>
                        </div>
                    ))}
                </div>
                <Goals />
            </div>
        </section>
    );
};

export default MissionValuesGoalsSection;
