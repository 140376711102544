import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import AboutSection3 from '../../Components/aboutSection/AboutSection3'
import Information1 from '../../Components/information/Information1'
import BannerAdd from '../../Components/bannerAdd/BannerAdd'
import Services1 from '../../Components/servicesSection/Services1'
import BannerAdd2 from '../../Components/bannerAdd/BannerAdd2'
import Carousel2 from '../../Components/reactcarouselSection/Carousel2'
import Counter from '../../Components/counter/Counter'
import BlogSection from '../../Components/blogSection/BlogSection'
import Services2 from '../../Components/servicesSection/CompanyGroup'

const AboutUs = () => {
    return (
        <>
            <HeroSection4 name={"About Us"} name2={"Home"} />
            <div class="page-content">
                <AboutSection3 />
                {/* <Services2 /> */}
                {/* <Information1 />
                <BannerAdd addName={"Creative Agency"} addName2={"Business Agency"} addName3={"Design Agency"} addName4={"Marketing Agency "} />
                <Services1 />
                <BannerAdd2 name={"Testimonial"} name2={"Client Feedback"} name3={"Awesome Review"} />\
                <Carousel2 />
                <Counter />
                <BlogSection /> */}
            </div>
        </>
    )
}

export default AboutUs
