import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo-02.png"
import logo3 from "../../assets/images/logo-03.png"
import logo4 from "../../assets/images/logo-04.png"
import { Link } from "react-router-dom";
import {
  Nav,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Collapse,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import API_BASE_URL from "../../api/API/baseUrl";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


const Footer = ({ data }) => {
  const [Visible, setVisible] = useState(false)
  const [loader, setLoader] = useState(true);
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);


  const gototop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  const [inputValue, setInputValue] = useState({
    email: "",
  })

  const onInputChange = (evt) => {
    const { name, value } = evt.target;
    setInputValue((prev) => ({ ...prev, [name]: value }))
  }
  const handleScroll = () => {
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    if (scrollTop > 100) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (loader === true) {
      setTimeout(() => setLoader(false), 2000);
    }
  }, [loader]);

  const [showModal, setShowModal] = useState(false);
  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);


  const submitNewsLetter = async () => {
    if (!validateEmail(inputValue.email)) {
      setMessage('Please enter a valid email address.');
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
      return;
    }

    const formData = new FormData();
    formData.append('email', inputValue.email);

    try {
      const response = await axios.post(`${API_BASE_URL}/newsletter`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        setMessage('Newsletter subscribed successfully!');
        setShowMessage(true);
        setInputValue({ email: '' });
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      } else {
        setMessage('Failed to submit newsletter.');
        setShowMessage(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred while submitting the newsletter.');
      setShowMessage(true);
    }
  };

  return (<>
    <footer className="footer">
      <div className="primary-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mt-lg-0">
              <NavLink className="footer-logo d-inline-block mb-5" to={"/"}>
                <h6 className="header-title" style={{ fontSize: "24px" }}>
                  LOGIC FUSION LLC
                </h6>
                <img src={logo4} style={{ height: "115px" }} />
              </NavLink>
              <div className="footer-cntct">
                <ul className="media-icon list-unstyled">
                  <li>
                    <i className="flaticon-location" />
                    <p className="mb-1">
                      {data?.address?.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          {index !== data?.address?.split('\n').length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    </p>
                  </li>
                  <li>
                    <i className="flaticon-chat" />
                    <NavLink to={`mailto:${data?.email}`}>{data?.email}</NavLink>
                  </li>
                  <li>
                    <i className="flaticon-phone-call" />
                    <NavLink to={`tel:${data?.phone}`}>+{data?.phone}</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <h5 className="mb-3" style={{ color: "#ff6a00" }}>Our Services</h5>
              <ul className="list-unstyled mb-0 footer-menu">

                <li className="mb-3">
                  <NavLink to="/"> Water Filters</NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/">Dewatering Pumps</NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/">Dewatering Equipments</NavLink>
                </li>

              </ul>
              <h5 className="mb-3 mt-6" style={{ color: "#ff6a00" }}>Our Products</h5>
              <ul className="list-unstyled mb-0 footer-menu">

                <li className="mb-3">
                  <NavLink to="/"> Dewatering Pumps</NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/">Pumps Shafts</NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/">Dewatering Equipments</NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="/">
                    Washing or Dewatering Machines
                  </NavLink>
                </li>

              </ul>
            </div>

            <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
              <h5 className="mb-3" style={{ color: "#ff6a00" }}>Our Group of Companies</h5>
              <h6 className="mb-2" style={{ color: "#ff6a00" }} >UAE</h6>
              <ul className="list-unstyled mb-4 footer-menu">

                <li className="mb-2">
                  <NavLink to="">Logic Fusion Land Drying Works LLC</NavLink>
                </li>
                <li className="mb-2">
                  <NavLink to="">Cosmic Gate Turnery Work Shop</NavLink>
                </li>
                <li className="mb-2">
                  <NavLink to="">Beacon & Bay Shipping Service LLC</NavLink>
                </li>

              </ul>
              <h6 className="mb-3" style={{ color: "#ff6a00" }} >KSA</h6>
              <ul className="list-unstyled mb-4 footer-menu">
                <li className="mb-4">
                  <NavLink to="">Al Mas Pumps Trading EST
                  </NavLink>
                </li>
              </ul>
              <h6 className="mb-2 mt-2" style={{ color: "#ff6a00" }}>India</h6>
              <ul className="list-unstyled mb-0 footer-menu">
                <li className="mt-2" style={{ marginBottom: "14px" }}>
                  <NavLink to="">Miller & Bauer International  </NavLink>
                </li>
                <li className="mb-3">
                  <NavLink to="">Pavali Pumps Industries  </NavLink>
                </li>

              </ul>

            </div>


            {/*     <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <h5 className="mb-4">Newsletter</h5>
              <div className="subscribe-form">
                <form id="mc-form">
                  <input
                    type="email"
                    value={inputValue.email}
                    name="email"
                    className="email form-control"
                    id="mc-email"
                    placeholder="Enter your email address"
                    required
                    onChange={onInputChange}
                  />
                  <div className="primary-btn mt-3">
                    <input
                      className="subscribe-btn"
                      type="submit"
                      name="subscribe"
                      defaultValue="Subscribe Now"

                    />
                  </div>
                </form>
              </div>
            </div> */}
          </div>

          <div className="row mt-5" style={{ padding: "10px" }}>
            <div className="col-lg-3 col-md-1"></div>
            <div className="col-lg-6 col-md-10 mt-5 mt-lg-0 border border-primary" style={{ padding: "25px", borderRadius: "25px" }}>
              <h3 className="mb-1">Subscribe to our Newsletter</h3>
              <div className="subscribe-form">
                <form id="mc-form" className="row align-items-center">
                  <div className="col">
                    <input
                      type="email"
                      value={inputValue.email}
                      name="email"
                      className="email form-control"
                      id="mc-email"
                      placeholder="Email Address"
                      required
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-auto">
                    <input
                      className="subscribe-btn btn btn-primary"
                      type="button"
                      name="subscribe"
                      value="Subscribe"
                      onClick={submitNewsLetter}
                    />
                  </div>
                </form>
                {showMessage && (
                  <div className="mt-3 text-white">
                    {message}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-3 col-md-1"></div>
          </div>

        </div>
      </div>
      <div className="secondary-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-12">
              ©2024 Logic Fusion LLC | Developed by<a style={{ color: "#ff6a00" }} className="text-theme px-2" target="_black" href="https://www.weberfox.com/">WeberFox Technologies Pvt. Ltd.</a>
            </div>
            <div className="col-lg-4 col-md-8 text-lg-end mt-3 mt-lg-0">
              <ul className="list-inline p-0 m-0 social-icons footer-social">
                <li className="list-inline-item">
                  <NavLink to="/" onClick={(e) => e.preventDefault()}>
                    <i className="la la-facebook" />
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink to="/" onClick={(e) => e.preventDefault()}>
                    <i className="la la-instagram" />
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink to="/" onClick={(e) => e.preventDefault()}>
                    <i className="la la-twitter" />
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink to="/" onClick={(e) => e.preventDefault()}>
                    <i className="la la-linkedin" />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <Modal isOpen={showModal} toggle={handleCloseModal} style={{ backgroundColor: "#1a1a1c" }}>
      <ModalHeader style={{ backgroundColor: "#1a1a1c", display: 'flex', padding: "25px", justifyContent: 'center', alignItems: 'center' }} toggle={handleCloseModal}>
        <div style={{ marginLeft: "60px" }}>Subscribe To Our Newsletter
        </div>

      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#1a1a1c" }}>
        {message && (
          <div className="alert alert-success" role="alert">
            {message}
          </div>
        )}
        <Form style={{ padding: "15px" }}>
          <FormGroup>
            <Input
              type="email"
              name="email"
              id="emailAddress"
              placeholder="Enter your email address"
              value={inputValue.email}
              onChange={onInputChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter style={{ backgroundColor: "#1a1a1c", display: 'flex', justifyContent: 'center' }}>
        <Button className='modal-primary-btn' onClick={submitNewsLetter}>
          Subscribe
        </Button>
        <Button className='modal-secondary-btn' onClick={handleCloseModal}>Cancel</Button>
      </ModalFooter>
    </Modal>

    <div className={`${Visible ? 'scroll-top scol' : ''}`} >
      <NavLink class="smoothscroll" onClick={gototop}>
        <i class="las la-arrow-up">
        </i>
      </NavLink>
    </div>
  </>

  );
};

export default Footer;
