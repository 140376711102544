import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import Portfolio2 from '../../Components/portfolioSection/Portfolio2'
import Portfolio3 from '../../Components/portfolioSection/Portfolio3'

const portfolio_single3 = () => {
  return (
    <>
      <HeroSection4 name={"Gallery"} name2={"Home"} />
      <div class="page-content">
        <Portfolio3 />
      </div>

    </>
  )
}

export default portfolio_single3
