import React from "react";
import bg3 from "../../assets/images/bg/03.jpg";
import { NavLink } from "react-router-dom";

const ContactSection = () => {
  return (
    <section
      style={{
        backgroundImage: `url(${bg3})`,
      }}
      data-overlay="9"
    >
      <div className="Contact-img"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="elementor-widget-contact-btn text-center position-relative">
              <h2>Connect With Us</h2>
              <NavLink to={"/contact-us"} className="round-text">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="100%"
                  height="100%"
                  viewBox="0 0 180 180"
                  enableBackground="new 0 0 180 180"
                  xmlSpace="preserve"
                >
                  <defs>
                    <path
                      id="circlePath"
                      d="M 90, 90 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "
                    />
                  </defs>
                  <circle cx={90} cy={90} r={75} fill="none" />
                  <g>
                    <use xlinkHref="#circlePath" fill="none" />
                    <text>
                      <textPath xlinkHref="#circlePath">
                        Get In Touch • Get In Touch •
                      </textPath>
                    </text>
                  </g>
                </svg>
              </NavLink>
              <NavLink
                className="contact-btn text-btn-icon"
                to="/contact-us"
              >
                <i aria-hidden="true" className="flaticon-forward" />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
