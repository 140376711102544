
import service6 from '../../assets/images/services/06.jpg'
import service7 from '../../assets/images/services/07.jpg'
import service8 from '../../assets/images/services/08.jpg'
import service9 from '../../assets/images/services/09.jpg'
import service10 from '../../assets/images/services/10.jpg'
import client1 from '../../assets/images/client/01.png';
import client2 from '../../assets/images/client/02.png';
import client3 from '../../assets/images/client/03.png';
import client4 from '../../assets/images/client/04.png';
import client5 from '../../assets/images/client/05.png';
import client6 from '../../assets/images/client/06.png';
import img1 from "../../assets/images/logos/logo-05.png"
import img2 from "../../assets/images/logos/cosmic_logo.png"
import img3 from "../../assets/images/logos/M&B.png"
import img4 from "../../assets/images/logos/logo_ppi_1.png"
import img5 from "../../assets/images/logos/ALMAS_1.png"
import img6 from "../../assets/images/logos/treebo_logo.png"



const CarouseApi3 = [
    {
        id: 1,
        title: "Logic Fusion Land Drying Works LLC",
        imageSrc: img1,
        desc: "Land drying solutions in the UAE",
        iconClass: "flaticon-land-drying",
        link: "/team/1",
        logoSrc: img1,
        location: "UAE"
    },
    {
        id: 2,
        title: "Cosmic Gate Turnery Work Shop",
        imageSrc: img2,
        desc: "Turnery workshop services in the UAE",
        iconClass: "flaticon-turnery-workshop",
        link: "/team/2",
        logoSrc: img2,
        location: "UAE"
    },
    // {
    //     id: 3,
    //     title: "Beacon & Bay Shipping Service LLC",
    //     imageSrc: "https://via.placeholder.com/400x300",
    //     desc: "Shipping services in the UAE",
    //     iconClass: "flaticon-shipping-service",
    //     link: "/team/3",
    //     logoSrc: client3,
    //     location: "UAE"
    // },
    {
        id: 4,
        title: "Al Raqiya Pumps Trading EST",
        imageSrc: img5,
        desc: "Pumps trading in KSA",
        iconClass: "flaticon-pumps-trading",
        link: "/team/4",
        logoSrc: img5,
        location: "KSA"
    },
    {
        id: 5,
        title: "Miller & Bauer International",
        imageSrc: img3,
        desc: "International trading in India",
        iconClass: "flaticon-international-trading",
        link: "/team/5",
        logoSrc: img3,
        location: "India"
    },
    {
        id: 6,
        title: "Pavali Pumps Industries",
        imageSrc: img4,
        desc: "Pumps manufacturing in India",
        iconClass: "flaticon-pumps-manufacturing",
        link: "/team/6",
        logoSrc: img4,
        location: "India"
    },
    {
        id: 7,
        title: "Treebo Rubber Industries",
        imageSrc: img6,
        desc: "Manufacturer and Exporter of rubber components and mouldingss",
        iconClass: "flaticon-pumps-manufacturing",
        link: "/team/6",
        logoSrc: img6,
        location: "India"
    },
];

export default CarouseApi3;
