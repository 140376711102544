import React, { useEffect, useState } from "react";
import HeroSection1 from "../../Components/heroSection/HeroSection1";
import Information1 from "../../Components/information/Information1";
import AboutSection from "../../Components/aboutSection/AboutSection";
import BannerAdd from "../../Components/bannerAdd/BannerAdd";
import Services1 from "../../Components/servicesSection/Services1";
import BannerAdd2 from "../../Components/bannerAdd/BannerAdd2";
import AboutSection2 from "../../Components/aboutSection/AboutSection2";
import VideoSection from "../../Components/videoSection/VideoSection";
import Carousel from "../../Components/reactcarouselSection/Carousel";
import Carousel2 from "../../Components/reactcarouselSection/Carousel2";
import Counter from "../../Components/counter/Counter";
import BlogSection from "../../Components/blogSection/BlogSection";
import ContactSection from "../../Components/contactSection/ContactSection";
import about1 from "../../assets/images/about/09.png"
import about3 from "../../assets/images/about/10.jpg"
import about4 from "../../assets/images/about/11.jpg"
import about2 from "../../assets/images/about/coporate_img_1.jpg"
import Carousel4 from "../../Components/reactcarouselSection/Carousel4";
import Project2 from '../../Components/projectSection/Project2'
import WhyChooseUs from "./HomeInfoOne";
import AboutSection4 from "../../Components/aboutSection/AboutSection4";

const HomeOne = () => {
  const [limit, setLimit] = useState()
  const handleInfinitescroll = async () => {
    // console.log("scrollTop" + document.documentElement.scrollTop); 
    try {
      if (document.documentElement.scrollTop >= 4500) {
        setLimit(true)
      }
      else if (document.documentElement.scrollTop === 0) { setLimit(false) }
    } catch (error) {
      console.log(error);
    }

  }
  useEffect(() => { window.addEventListener('scroll', handleInfinitescroll, { passive: true }) }, [])
  return (
    <>
      <HeroSection1 />
      <div class="page-content mt-5">
        {/*  <Information1 /> */}
        <AboutSection img={about2} img2={about3} img3={about4} />
        <AboutSection2 />
        {/* <BannerAdd addName={"Dewatering Focus"} addName2={"Comprehensive Industrial Expertise"} addName3={"Reliable Equipment Rental & Repair Services"} /> */}
        <Services1 />
        <WhyChooseUs />
        <Information1 />
        <BannerAdd2 name={"Our Products"} name2={"Our Products"} name3={"Our Works"} />
        <Carousel />
        <AboutSection4 />
        {/*     <BlogSection />  
        {/*  <ContactSection /> */}
        <Project2 />
        <BannerAdd2 name={"Testimonial"} name2={"Client Feedback"} name3={"Customer Review"} />
        <Carousel2 />

        {<Counter />}
        <VideoSection />
        <BannerAdd2 name={"Our Ventures"} name2={"Our Ventures"} name3={"Our Ventures"} />
        <Carousel4 />
        <ContactSection />

      </div>
    </>
  );
};

export default HomeOne;
