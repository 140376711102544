import React from "react";
import ProgressBar from "../common/ProgressBar";
import { NavLink, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import CompaniesAPI from "../../api/CompaniesApi/CompaniesApi";

const TeamDetails = () => {
  // const myData = JSON.parse(localStorage.getItem("team"));
  const { id } = useParams();
  const companyId = parseInt(id, 10);

  const company = CompaniesAPI.find(company => company.id === companyId);

  return (
    <>
      {company ?
        <section>
          <div className="container">
            <div className="team-single-page team-member">
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="team-images">
                    <img className="img-fluid" src={company.imageSrc} alt={company.name} />
                  </div>
                  <div className="team-contact">
                    <ul className="list-unstyled team-meta">
                      <li>
                        <i className="flaticon-phone-call" />
                        <div>
                          <span>Phone:</span>
                          <NavLink to="/">{company.phone}</NavLink>
                        </div>
                      </li>
                      <li>
                        <i className="flaticon-chat" />
                        <div>
                          <span>Email:</span>
                          <NavLink to={`mailto:${company.email}`}>{company.email}</NavLink>
                        </div>
                      </li>
                    </ul>
                    <ul className="team-social-icon list-inline mt-4 mb-0">
                      {company.socialLinks.map((link, index) => (
                        <li key={index}>
                          <NavLink to={link.url}>
                            <i className={`la la-${link.platform}`} />
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  
                </div>
                <div className="col-lg-8 col-md-12 mt-5 mt-lg-0 ps-lg-6">
                  <div className="team-description theme-title">
                    <h6>Company Overview</h6>
                    <h5>{company.name}</h5>
                    <p>{company.overview}</p>
                    <div className="row my-5">
                      {company.services.map((service, index) => (
                        <FeaturedItem
                          key={index}
                          icon={service.iconClass}
                          title={service.title}
                          description={service.description}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> :
        <section>
          <div className="container">
            <div className="team-single-page team-member">
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="team-images">
                    <img className="img-fluid" src={require("../../assets/images/team/01.jpg")} alt="" />
                  </div>
                  <div className="team-contact">
                    <ul className="list-unstyled team-meta">
                      <li>
                        <i className="flaticon-phone-call"></i>
                        <div>
                          <span>Phone:</span>
                          <a href="tel:+91-234-567-8900">+91-234-567-8900</a>
                        </div>
                      </li>
                      <li>
                        <i className="flaticon-chat"></i>
                        <div>
                          <span>Email:</span>
                          <a href="mailto:info@example.com">info@example.com</a>
                        </div>
                      </li>
                      {/* <li>
                        <i className="flaticon-content-management"></i>
                        <div>
                          <span>Specialist:</span>Web Developer
                        </div>
                      </li>
                      <li>
                        <i className="flaticon-calendar"></i>
                        <div>
                          <span>Experience:</span>7+ Years
                        </div>
                      </li> */}
                    </ul>
                    <ul className="team-social-icon list-inline mt-4 mb-0">
                      <li>
                        <a href="#!">
                          <i className="la la-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="la la-dribbble"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="la la-twitter"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 mt-5 mt-lg-0 ps-lg-6">
                  <div className="team-description">
                    <h3>Company Overview</h3>
                    <p>Uetium, pellentesque faucibus sodales id donec faucibus laoreet laoreet sit. Est diam pulvinar commodo aliquet lectus. Uetium, pellentesque faucibus sodales id donec faucibus laoreet laoreet commodo.</p>
                    {/* <div className="row mb-7">
                      <div className="col-md-6">
                        <div className="theme-progress-bar">
                          <h4>Web Development</h4>
                          <div className="progress" data-value="90">
                            <div className="progress-bar">
                              <div className="progress-parcent">90%</div>
                            </div>
                          </div>
                        </div>
                        <div className="theme-progress-bar mt-4">
                          <h4>Graphic Design</h4>
                          <div className="progress" data-value="80">
                            <div className="progress-bar">
                              <div className="progress-parcent">80%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="theme-progress-bar">
                          <h4>Creative Design</h4>
                          <div className="progress" data-value="95">
                            <div className="progress-bar">
                              <div className="progress-parcent">95%</div>
                            </div>
                          </div>
                        </div>
                        <div className="theme-progress-bar mt-4">
                          <h4>WordPress</h4>
                          <div className="progress" data-value="85">
                            <div className="progress-bar">
                              <div className="progress-parcent">85%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                    <div className="row my-5">
                      <div className="col-lg-4 col-md-6">
                        <div className="featured-item style-1">
                          <div className="featured-icon">
                            <i className="flaticon-content-management"></i>
                          </div>
                          <div className="featured-description">
                            <div className="featured-title">
                              <h5>Content Marketing</h5>
                            </div>
                            <p>There are many variations of passages of Lorem Ipsum available.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 mt-5 mt-md-0">
                        <div className="featured-item style-1">
                          <div className="featured-icon">
                            <i className="flaticon-seo-2"></i>
                          </div>
                          <div className="featured-description">
                            <div className="featured-title">
                              <h5>SEO Services</h5>
                            </div>
                            <p>There are many variations of passages of Lorem Ipsum available.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 mt-5 mt-lg-0">
                        <div className="featured-item style-1">
                          <div className="featured-icon">
                            <i className="flaticon-seo"></i>
                          </div>
                          <div className="featured-description">
                            <div className="featured-title">
                              <h5>Distribution</h5>
                            </div>
                            <p>There are many variations of passages of Lorem Ipsum available.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Link className="rounded-button" to={`/team`}>
                      <i className="flaticon-forward"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};
export default TeamDetails;
const FeaturedItem = ({ icon, title, description }) => (
  <div className="col-lg-4 col-md-6 mt-2">
    <div className="featured-item style-1">
      <div className="featured-icon">
        <i className={icon} />
      </div>
      <div className="featured-description">
        <div className="featured-title">
          <h5>{title}</h5>
        </div>
        <p>{description}</p>
      </div>
    </div>
  </div>
);
