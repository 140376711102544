import React, { useEffect, useState } from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import TeamMember from '../../Components/teamMember/TeamMember'
import Services3 from '../../Components/servicesSection/Services3'
import axios from 'axios'
import API_BASE_URL from '../../api/API/baseUrl'


const Team = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviceResponse = await axios.get(`${API_BASE_URL}/service`);
        setData(serviceResponse.data.data);
        console.log(serviceResponse.data.data); 
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <HeroSection4 name={"Our Services"} name2={"Home"} />
      <div className="page-content">
        {/* <TeamMember /> */}
        <Services3 serviceData={data} />
        </div>
    </>
  )
}

export default Team;