import Services1 from "../../assets/images/services/17.png";
import Services2 from "../../assets/images/services/26.jpg";
import Services3 from "../../assets/images/services/23.jpg";
import Services4 from "../../assets/images/services/19.jpg";
import Services5 from "../../assets/images/services/18.png";
const ServicesApi1 = [
  {
    id: 1,
    title: "Global Dewatering Solutions",
    description: " Spearheading the export/import of dewatering equipment and top-brand spare parts, ensuring efficient water management solutions globally.",
    img: Services1,
    path: "/"
  },
  {
    id: 2,
    title: "Integrated Equipment Services",
    description: "Comprehensive offerings include hydraulic hoses, industrial hose assemblies, equipment rental, repair, and trade to support seamless operations.",
    img: Services2,
    path: "/"
  },
  {
    id: 3,
    title: "Diverse Industrial Solutions",
    description: " Delivering UPVC well points, PVC pipes, truck spare parts, and diverse industrial equipment to meet varied industry requirements with quality assurance.",
    img: Services3,
    path: "/"
  },
  {
    id: 4,
    title: "Cutting-Edge Foundry & Precision Machining",
    description: "Advanced capabilities in precision casting, molding, and tool manufacturing, ensuring excellence in every project execution.",
    img: Services4,
    path: "/"
  },
  {
    id: 5,
    title: "Specialized Contract Services",
    description: "Providing core cutting, drilling equipment rental, and heavy equipment leasing for construction and industrial projects, backed by competitive pricing and swift delivery.",
    img: Services5,
    path: "/"
  },
];
export default ServicesApi1;
