import portfolio1 from "../../assets/images/portfolio/01.jpg";
import portfolio2 from "../../assets/images/portfolio/02.jpg";
import portfolio3 from "../../assets/images/portfolio/03.jpg";
import portfolio4 from "../../assets/images/portfolio/04.jpg";
import portfolio5 from "../../assets/images/portfolio/05.jpg";

const ProjectApi = [
    {
        id: 1,
        title: "Export & Import of Dewatering Pumps & Accessories",
        subTitle: "International Trade",
        imageSrc: 'https://media.licdn.com/dms/image/D5622AQEO-6pec6_urQ/feedshare-shrink_800/0/1697760728716?e=2147483647&v=beta&t=0chXfAbGKJKEWVHhhlXzgV80scGy90gFqo06Vry9YRo',
        titleLink: "/DeWateringPumps",
        subTitleLink: "/",
    },
    {
        id: 2,
        title: "Manufacturing of Spare Parts for Dewatering Pumps",
        subTitle: "Industrial Manufacturing",
        imageSrc: 'https://www.mbexports.com/uploads/gallery/Xjf9TbLQFg.JPG',
        titleLink: "/Impellers",
        subTitleLink: "/",
    },
    {
        id: 3,
        title: "Hydraulic & Industrial Hoses & Assembly",
        subTitle: "Engineering Solutions",
        imageSrc: 'https://africanminingmarket.com/wp-content/uploads/2022/12/atlas_copco_pac_ipr.jpg',
        titleLink: "/LayflatHose",
        subTitleLink: "/",
    },
    {
        id: 4,
        title: "Rental & Repairing of Dewatering Pumps & Accessories",
        subTitle: "Equipment Services",
        imageSrc: 'https://www.canadiandewatering.com/files/cdig/2017-09/land-pump_0.jpg',
        titleLink: "/PumpRepairs",
        subTitleLink: "/",
    },
    {
        id: 5,
        title: "Foundry Section: Casting & Molding",
        subTitle: "Metal Works",
        imageSrc: 'https://www.clinchrivercasting.com/wp-content/uploads/clinch-river-casting-15-e1522414307756.jpg',
        titleLink: "/CastIronWorks",
        subTitleLink: "/",
    },
    {
        id: 6,
        title: "Machining Milling and Fabrication ",
        subTitle: "Precision Engineering Services",
        imageSrc: 'https://www.clinchrivercasting.com/wp-content/uploads/clinch-river-casting-15-e1522414307756.jpg',
        titleLink: "/PrecisionMachining",
        subTitleLink: "/",
    },
];

export default ProjectApi;
