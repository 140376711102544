const portfolioApi = [
  {
    icon: "flaticon-expert",
    title: "TRADE REWARDS",
    subTitle: "Exclusive benefits for frequent shoppers",
  },
  {
    icon: "flaticon-award",
    title: "INSIDER REWARDS",
    subTitle: "Special deals for loyal customers",
  },
  {
    icon: "flaticon-calendar",
    title: "30 DAY RETURNS",
    subTitle: "Hassle-free returns within 30 days",
  },
  {
    icon: "flaticon-tag",
    title: "PRICE MATCH GUARANTEE",
    subTitle: "We match any competitor's price",
  },
  {
    icon: "flaticon-location",
    title: "FAST DELIVERY",
    subTitle: "Quick and reliable delivery service",
  },
];

export default portfolioApi;
