import React from "react";
import AboutApi1 from "../../api/AboutApi1";
import AgencySvg from "../svg/Agency";
import { NavLink } from "react-router-dom";
import OurMissionApi from "../../api/OurMissionApi";
import mission from "../../assets/images/about/mission_3.jpg"
const placeholderImageUrl = 'https://e0.pxfuel.com/wallpapers/28/782/desktop-wallpaper-for-business-corporate-meeting.jpg';


const OurMission = ({ img, imgStyle }) => {
    return (
        <>
            <section className="about-section pt-0" style={{ paddingBottom: "25px" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 position-relative">
                            <img
                                className={`img-fluid rounded-bottom${imgStyle}`}
                                src={mission}
                                style={{ borderRadius: "25px", height: "500px", width: "100%" }}
                                alt="Placeholder Image"

                            />
                            {imgStyle ? "" : <div className="round-text">
                                <AgencySvg label={"Logic Fusion LLC • Logic Fusion LLC •"} icon={"flaticon-target"} />
                            </div>}
                        </div>
                        <div className="col-12 col-lg-6 ps-lg-5 mt-5 mt-lg-0">
                            <p style={{ textAlign: "justify", marginTop: "15px" }}>
                                <div className="theme-title">
                                    <h2 style={{ textAlign: "justify", marginTop: "25px" }}>Our Mission</h2>
                                    Our mission is to provide top-tier dewatering and pumping solutions that not
                                    only meet the highest standards of quality and performance but also contribute
                                    to environmental sustainability. We aim to create a positive impact on the
                                    environment by implementing eco-friendly practices and developing products
                                    that support a sustainable future.
                                </div>
                            </p>
                            <ul style={{ textAlign: "left", marginTop: "15px", paddingLeft: "0", listStyleType: "none" }}>
                                {OurMissionApi.map((mission, index) => (
                                    <li key={index} style={{ marginBottom: "10px" }}>
                                        <i className="la la-check-circle" style={{ color: "#ff6a00", marginRight: "10px" }}></i>
                                        {mission.details}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OurMission;
