import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import Portfolio2 from '../../Components/portfolioSection/Portfolio2'

const portfolio_single2 = () => {
  return (
    <>

      <HeroSection4 name={"Gallery"} name2={"Home"} />
      <div class="page-content">
        <Portfolio2 />
      </div>

    </>
  )
}

export default portfolio_single2
