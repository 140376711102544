/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import Odometer from 'react-odometerjs';
import ProgressBar from '../common/ProgressBar';
import img1 from "../../assets/images/about/mohammedshibilibadarudeen.jpg"
import img2 from "../../assets/images/about/indianflag.jpg"
import img3 from "../../assets/images/about/ksaFlag.jpg"
import img4 from "../../assets/images/about/uaeflag.jpg"
import img5 from"../../assets/images/about/director.jpg"


const AboutInfoOne = () => {
    const [value, setValue] = useState(0);
    const [strategicPartnershipsCount, setStrategicPartnershipsCount] = useState(50);
    const [innovationProjectsCount, setInnovationProjectsCount] = useState(20);
    const [teamAwards, setTeamAwards] = useState(1000);

    // scrollToTop
    const [limit, setLimit] = useState(false)
    const handleInfinitescroll = async () => {
        // console.log("scrollTop" + document.documentElement.scrollTop); 
        try {
            if (document.documentElement.scrollTop >= 200) {
                setLimit(true)
            }
            else if (document.documentElement.scrollTop === 0) {
                setLimit(false)
            }
        } catch (error) {
            console.log(error);
        }
    }
    // scrollToTop
    useEffect(() => { window.addEventListener('scroll', handleInfinitescroll, { passive: true }) }, [])
    useEffect(() => {
        const timeoutId = setTimeout(() => setValue(10), 1000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const testimonialRef1 = useRef(null);
    const testimonialRef2 = useRef(null);

    useEffect(() => {
        const observer1 = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.style.opacity = 1;
                }
            });
        }, { threshold: 0.5 });

        if (testimonialRef1.current) {
            observer1.observe(testimonialRef1.current);
        }

        const observer2 = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.style.opacity = 1;
                }
            });
        }, { threshold: 0.5 });

        if (testimonialRef2.current) {
            observer2.observe(testimonialRef2.current);
        }

        return () => {
            if (testimonialRef1.current) {
                observer1.unobserve(testimonialRef1.current);
            }
            if (testimonialRef2.current) {
                observer2.unobserve(testimonialRef2.current);
            }
        };
    }, []);

    return (
        <>
            <section style={{ paddingBottom: "25px" }} className="about-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 position-relative">
                            <div className="row">
                                <div className="col-md-6 mt-10">
                                    <img style={{ padding: "5px" }} src={require("../../assets/images/about/03.jpg")} className="img-fluid rounded" alt="Team" onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')} />
                                </div>
                                <div className="col-md-6">
                                    <img style={{ padding: "5px" }} src={require("../../assets/images/about/about_image_2.jpg")} className="img-fluid rounded mt-11" alt="Office" onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')} />
                                    <img style={{ padding: "5px" }} src={require("../../assets/images/about/about_image_3.jpg")} className="img-fluid rounded mt-11" alt="Office" onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 ps-lg-5 mt-5 mt-lg-0">
                            <div className="theme-title mt-6">
                                <h6>About Us</h6>
                                <h2>Company Overview</h2>
                                <p className='justify-text'>Our company has established itself as a leading provider of innovative solutions in the field of dewatering, pumps, and related services. With nearly two decades of industry experience, we have consistently delivered high-quality products and services that meet the evolving needs of our clients across various sectors.</p>
                            </div>

                            <div className="row mt-4 align-items-center">
                                <div className="col-md-6">
                                    <div className="counter style-2 bg-dark-2 text-center p-5">
                                        <div className="counter-icon">
                                            <i className="flaticon-award" />
                                        </div>
                                        <div className="counter-desc d-flex justify-content-center">
                                            <span className="count-number">
                                                <Odometer value={limit ? value : ""} />
                                            </span>
                                            <span>K</span>
                                        </div>
                                        <h6>Projects Completed</h6>
                                    </div>
                                </div>
                                {<div className="col-md-6">
                                    <ProgressBar value={limit ? 100 : ""} title={"Business Success"} />
                                    <ProgressBar value={limit ? 95 : ""} title={"Year Of Work"} />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{ paddingBottom: "25px" }} className="about-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 ps-lg-5 mt-5 mt-lg-0">
                            <div className="theme-title mt-6">
                                <h2>Global Reach & Partnerships</h2>
                                <p className='justify-text'>With a presence in multiple countries, we have established a global network that enables us to serve clients worldwide. Our subsidiaries in KSA, India, and UAE play a vital role in extending our reach and delivering exceptional services tailored to local markets. Through strategic partnerships and collaborations with industry leaders, we continuously enhance our capabilities and remain at the forefront of innovation.</p>
                            </div>
                            <div className="row mt-4 align-items-center">
                                <div className="col-md-12">
                                    <div className="counter style-2 bg-dark-2 text-center p-5">
                                        <div className="counter-icon">
                                            <i className="la la-globe" />
                                        </div>
                                        <div className="counter-desc d-flex justify-content-center">
                                            <span className="count-number">
                                                <Odometer value={limit ? value : ""} />
                                            </span>
                                            <span>+</span>
                                        </div>
                                        <h6>Countries Served</h6>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="achievement-item text-center mb-4" style={{ flex: '0 0 50%', backgroundColor: '#1a1a1c', padding: '20px', borderRadius: '10px', color: 'white', marginLeft: '10px' }}>
                                        <i className="la la-trophy" style={{ fontSize: '2rem', color: 'var(--theme-primary-color)' }} />
                                        <h6 className="mt-2">Team</h6>
                                        <div className="counter-desc d-flex justify-content-center"></div>
                                        <p style={{ marginBottom: '20px' }}>Team of <Odometer value={limit ? teamAwards : ""} />+ professionals</p>
                                    </div>
                                </div> */}
                            </div>
                            <div className="row mt-4">
                                <div className="achievement-list d-flex flex-column flex-md-row justify-content-between">
                                    <div className="achievement-item text-center mb-4" style={{ flex: '0 0 30%', backgroundColor: '#1a1a1c', padding: '20px', borderRadius: '10px', color: 'white', margin: '10px 0', marginRight: '10px' }}>
                                        <i className="la la-handshake" style={{ fontSize: '2rem', color: 'var(--theme-primary-color)' }} />
                                        <h6 className="mt-2">Strategic Partnerships</h6>
                                        <div className="counter-desc d-flex justify-content-center"></div>
                                        <p style={{ marginBottom: '20px' }}>Over <Odometer value={limit ? strategicPartnershipsCount : ""} />+ partnerships worldwide</p>
                                    </div>
                                    <div className="achievement-item text-center mb-4" style={{ flex: '0 0 30%', backgroundColor: '#1a1a1c', padding: '20px', borderRadius: '10px', color: 'white', margin: '10px' }}>
                                        <i className="la la-rocket" style={{ fontSize: '2rem', color: 'var(--theme-primary-color)' }} />
                                        <h6 className="mt-2">Innovation Projects</h6>
                                        <div className="counter-desc d-flex justify-content-center"></div>
                                        <p style={{ marginBottom: '20px' }}><Odometer value={limit ? innovationProjectsCount : ""} />+ innovation projects</p>
                                    </div>
                                    <div className="achievement-item text-center mb-4" style={{ flex: '0 0 30%', backgroundColor: '#1a1a1c', padding: '20px', borderRadius: '10px', color: 'white', margin: '10px 0', marginLeft: '10px' }}>
                                        <i className="la la-trophy" style={{ fontSize: '2rem', color: 'var(--theme-primary-color)' }} />
                                        <h6 className="mt-2">Team</h6>
                                        <div className="counter-desc d-flex justify-content-center"></div>
                                        <p style={{ marginBottom: '20px' }}>Team of <Odometer value={limit ? teamAwards : ""} />+ professionals</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 position-relative">
                            <div className="row">
                                <div className="col-md-6 mt-10">
                                    <img
                                        style={{ padding: "5px" }}
                                        src={img4}
                                        className="img-fluid rounded"
                                        alt="Global Reach"
                                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                                    />

                                </div>
                                <div className="col-md-6">
                                    <img
                                        style={{ padding: "5px" }}
                                        src={img3}
                                        className="img-fluid rounded mt-5 mt-md-0"
                                        alt="Partnerships"
                                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                                    />
                                    <img
                                        style={{ padding: "5px" }}
                                        src={img2}
                                        className="img-fluid rounded mt-3"
                                        alt="Image 1"
                                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                                    />
                                    {/* <img
                                        style={{ padding: "5px" }}
                                        src="https://cdn.britannica.com/73/5773-050-1AA1BA5C/Flag-Oman.jpg"
                                        className="img-fluid rounded mt-3"
                                        alt="Collaborations"
                                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                                    /> */}
                                    {/* <img
                                        style={{ padding: "5px" }}
                                        src="https://cdn.britannica.com/70/5770-004-A99DD01D/Flag-Kuwait.jpg"
                                        className="img-fluid rounded mt-3"
                                        alt="Image 2"
                                        onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                                        onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section style={{ paddingTop: "25px", paddingBottom: "25px" }} className="testimonial-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="item">
                                <div ref={testimonialRef1} className="testimonial style-1" style={{ paddingTop: "25px", paddingBottom: "25px", opacity: 0, transition: "opacity 1s ease-in-out" }}>
                                    <div className="testimonial-img">
                                        <img className="img-fluid"  style={{ width: "250px", height: "250px" }} src={img5} alt="DIRECTOR" />
                                        <div className="testimonial-caption">
                                            <h5>Mohammed Shafeek Shereef</h5>
                                            <span style={{ fontSize: "1.2rem" }}>Director</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-content">
                                        <p className='justify-text-md' style={{ fontSize: "1rem" }}>Mohammed Shafeek Shereef is the founder and director of Logicfusion Group. A visionary leader who has spearheaded numerous successful projects with excellent leadership and communication skills. Highly committed, dedicated, and approachable individual with strong business ethics and policies. With over 36 years of Gulf experience in the industry, Shereef has led various successful dewatering projects in Abu Dhabi and Western regions. Known for a responsible approach towards customers and proper cognizance about manufacturing pumps and accessories, fabrication, casting of ferrous and non-ferrous materials, and trading various industrial engine spares.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-5">
                            <div className="item">
                                <div ref={testimonialRef2} className="testimonial style-1" style={{ opacity: 0, transition: "opacity 1s ease-in-out" }}>
                                    <div className="testimonial-content">
                                        <p className='justify-text-md' style={{ fontSize: "1rem" }}>A well-disciplined Ex-Indian Air force Personal with a very good academic and professional background with Bachelor or Law & Masters in Business Administration (MBA) in Marketing and Human Resources Management, from one of the most reputed Institutes in India.
                                            He has manufacturing, administration, trading and international business experience of over 24 years and has extensively travelled for business in many countries and succeeded in finalizing many prestigious business deals with reputed customers.</p>
                                    </div>
                                    <div className="testimonial-img">
                                        <img className="img-fluid" style={{ width: "200px", height: "200px" }} src={img1} alt="Managing Director" />
                                        <div className="testimonial-caption">
                                            <h5>Mohammed Shibili Badarudeen</h5>
                                            <span style={{ fontSize: "1.2rem" }}>Managing Director</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default AboutInfoOne;
