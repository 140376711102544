import React from 'react'
import BlogLeftApi from '../../api/BlogApi/BlogLeftApi'
import SaidbarApi from '../../api/SaidbarApi'
import { useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
const SidebarProd = ({ subCategories, onSubCategorySelect }) => {
    return (
        <div className="theme-sidebar">
            <div className="theme-widget widget">
                <h2 className="mb-3">CATEGORIES</h2>
                <ul className="list-unstyled">
                    {subCategories && subCategories.map((subCategory, index) => (
                        <li key={subCategory.id} className="mb-3">
                            <NavLink
                                onClick={() => onSubCategorySelect(subCategory.id)}
                                className="d-flex align-items-center"
                            >
                                <i className="la la-cube mr-2" style={{ color: '#ff6a00', marginRight: '10px' }}></i>
                                {subCategory.name} <span className="text-theme">
                                    {/* ({subCategory.length}) */}
                                </span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SidebarProd;