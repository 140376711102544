import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectApi from "../../api/ProjectApi/ProjectApi";
import { NavLink } from "react-router-dom";
import ProjectApi3 from "../../api/ProjectApi/ProjectApi3";
import API_IMAGE_URL from "../../api/API/baseImageUrl";



const Project3 = ({ margin, data }) => {
    const [hoveredServiceIndex, setHoveredServiceIndex] = useState(null);
    const navigate = useNavigate();

    const portfolio = (link) => {
        navigate(`${link}`);
    };

    return (
        <section style={{paddingBottom:"25px"}}>
            <div className="container">
                <div className="row">
                    {data && data.map((category, categoryIndex) => (
                        category.subcategories && category.subcategories.map((subcategory, subcategoryIndex) => (
                            subcategory.items && subcategory.items.map((item, itemIndex) => (
                                <div key={`${category.id}-${categoryIndex}-${subcategoryIndex}-${itemIndex}`} className="col-md-4 mb-4">
                                    <div
                                        className={`portfolio-item style-2 ${hoveredServiceIndex === `${category.id}-${categoryIndex}-${subcategoryIndex}-${itemIndex}` ? "active" : ""}`}
                                        onMouseEnter={() => setHoveredServiceIndex(`${category.id}-${categoryIndex}-${subcategoryIndex}-${itemIndex}`)}
                                        onMouseLeave={() => setHoveredServiceIndex(null)}
                                        onClick={() => portfolio(`/gallery/${category.id}/${subcategory.id}/${item.id}`)}
                                        style={{ cursor: 'pointer', transition: 'all 0.3s ease' }}
                                    >
                                        <div className="portfolio-image">
                                            {item.images.length > 0 ? (
                                                <img
                                                    src={API_IMAGE_URL + item.images[0].image}
                                                    className="img-fluid"
                                                    alt=""
                                                    style={{ height: "200px", objectFit: "cover", transition: 'all 0.3s ease' }}
                                                />
                                            ) : (
                                                <img
                                                    src="http://via.placeholder.com/500"
                                                    className="img-fluid"
                                                    alt="Placeholder"
                                                    style={{ height: "200px", objectFit: "cover", transition: 'all 0.3s ease' }}
                                                />
                                            )}
                                        </div>
                                        <div className="portfolio-desc" style={{ opacity: hoveredServiceIndex === `${category.id}-${categoryIndex}-${subcategoryIndex}-${itemIndex}` ? 1 : 0, transition: 'opacity 0.3s ease' }}>
                                            <h5>{item.title}</h5>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ))
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Project3;