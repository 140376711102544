import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import TeamDetails from '../../Components/teamDetails/TeamDetails'
import ProductsPage from './ProductsPage'

const Products = () => {
    return (
        <>
            <HeroSection4 name={"Our Products"} name2={"Home"} />
            <div class="page-content">
                <ProductsPage />
            </div>
        </>
    )
}

export default Products
