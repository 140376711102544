import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProjectApi4 from '../../api/ProjectApi/ProjectApi4';
import API_BASE_URL from '../../api/API/baseUrl';
import axios from 'axios';



const Portfolio2 = () => {
    const { categoryId, id } = useParams();
    const [category, setCategory] = useState(null);
    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const foundCategory = ProjectApi4.find(category =>
            category.categoryId === parseInt(categoryId) &&
            category.items.some(item => item.id === parseInt(id))
        );

        setCategory(foundCategory);

        if (foundCategory) {
            const foundItem = foundCategory.items.find(item => item.id === parseInt(id));
            setImages(foundItem.images);
        }
    }, [categoryId, id]);

 

    const openImage = (image) => {
        setSelectedImage(image);
    };

    const closeImage = () => {
        setSelectedImage(null);
    };

    if (!images.length) {
        return <div>Loading...</div>;
    }

    const itemTitle = category.items.find(item => item.id === parseInt(id)).title;

    return (
        <>
            <div className="container">
                <div className="row justify-content-center mt-4 mb-3">
                    <h1 style={{ textAlign: "center", margin: "20px 0" }}>{itemTitle}</h1>
                </div>
                <div className="row">
                    {images.map((image, index) => (
                        <div key={image.id} className="col-lg-4 col-md-6" style={{ cursor: "pointer" }} onClick={() => openImage(image)}>
                            <section key={index} className={`pt-0 item mt-4 margin`}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <div className="no-pb portfolio-item style-1">
                                                <div id="portfolio-desc" className="item"></div>
                                                <img style={{ height: "400px", borderRadius: "10px", objectFit: "cover" }} className="portfolio-border-radius img-fluid" src={image.imageUrl} alt={`Image ${image.id}`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    ))}
                </div>
            </div>
            {selectedImage && (
                <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.7)", zIndex: 999, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ padding: "20px", backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
                        <img style={{ maxWidth: "100%", maxHeight: "80vh" }} src={selectedImage.imageUrl} alt={`Image ${selectedImage.id}`} />
                        <button className="primary-btn text-decoration-none" style={{ position: "absolute", top: "10px", right: "10px", zIndex: 1000 }} onClick={closeImage}>Close</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Portfolio2;