const WhyChooseUsData = [
    {
        title: 'Expertise and Experience',
        description: 'With years of experience in the industry, we understand the complexities of dewatering and water management solutions.',
        icon: 'la la-briefcase', 
    },
    {
        title: 'Quality Products',
        description: 'We provide top-quality dewatering pumps and accessories from trusted brands, ensuring reliability and efficiency in water extraction and management.',
        icon: 'la la-check-circle',
    },
    {
        title: 'Customized Solutions',
        description: "Our solutions are tailored to meet specific industrial needs, whether it's for construction, mining, or municipal projects.",
        icon: 'la la-wrench',
    },
    {
        title: 'Comprehensive Services',
        description: 'From equipment sales to rental, repair, and maintenance services, we offer a full spectrum of services to support your operations.',
        icon: 'la la-tools',
    },
    {
        title: 'Customer Satisfaction',
        description: 'Our commitment to customer satisfaction drives everything we do. We strive to exceed expectations in product quality, service delivery, and support.',
        icon: 'la la-user',
    },
    {
        title: 'Innovation and Efficiency',
        description: '  Embracing cutting-edge technology, we continuously innovate to enhance efficiency and productivity in dewatering processes.',
        icon: 'la la-lightbulb-o',
    },
    {
        title: 'Global Reach',
        description: 'We have a robust global network, ensuring timely delivery and support wherever your operations are located.',
        icon: 'la la-globe',
    },
];

export default WhyChooseUsData;
