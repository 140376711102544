import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import ProjectApi2 from '../../api/ProjectApi/ProjectApi2';
import AgencySvg from '../svg/Agency';
import ProjectApi3 from '../../api/ProjectApi/ProjectApi3';
import axios from 'axios';
import API_BASE_URL from '../../api/API/baseUrl';
import API_IMAGE_URL from '../../api/API/baseImageUrl';

const Project2 = () => {
    const [projectItems, setProjectItems] = useState([]);
    const [categoryId, setCategoryId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryResponse = await axios.get(`${API_BASE_URL}/gallery`);

                const projectsData = galleryResponse.data.data.find(category => category.name === "Projects");
                const categoryId = projectsData ? projectsData.id : null;
                setCategoryId(categoryId);

                const items = projectsData ? projectsData.subcategories.flatMap(subcategory =>
                    subcategory.items.map(item => ({
                        ...item,
                        subcategoryId: subcategory.id
                    }))
                ) : [];
                setProjectItems(items);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchData();
    }, []);

    const owlOptions = {
        items: 3,
        loop: true,
        responsive: {
            0: { items: 1 },
            576: { items: 2 },
            992: { items: 3 },
        },
        margin: 10,
        autoplay: true,
    };

    return (
        <section style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-3 text-center">
                        <div className="round-text">
                            <AgencySvg label={"Latest Works • Services • Projects •"} icon={"flaticon-project-management-1"} />
                        </div>
                        <div className="theme-title">
                            <h2>Our Works</h2>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <OwlCarousel className="owl-carousel" {...owlOptions}>
                            {projectItems.map((item, index) => (
                                <div className="item" key={index}>
                                    <div className="portfolio-item style-3">
                                        <div className="portfolio-image">
                                            <img
                                                src={API_IMAGE_URL +
                                                    item.images[0]?.image ||
                                                    'https://via.placeholder.com/250x250'}
                                                className="img-fluid"
                                                alt={item.title}
                                                style={{
                                                    width: '100%',
                                                    height: '350px',
                                                    objectFit: 'cover'
                                                }}
                                            />
                                        </div>
                                        <div className="portfolio-desc">
                                            <h5>
                                                <Link to={`/gallery/${categoryId}/${item.subcategoryId}/${item.id}`} className="text-decoration-none">{item.title}</Link>
                                            </h5>
                                            {/* <p>{item.description}</p> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>

            {/* <div className="container" style={{ marginLeft: "50px" }}>
    <div className="row">
        <div className='col-lg-3'>
        </div>
        <div className="col-lg-9">
            <div className="theme-title">
                <h3>Explore Our Works and Projects</h3>
            </div>
            <p>
                At our company, we take pride in our extensive portfolio of dewatering projects. Our works encompass a wide range of industries, including construction, mining, agriculture, and environmental management. We specialize in providing innovative dewatering solutions tailored to the unique requirements of each project.
            </p>
            <p>
                Our team of experienced professionals ensures the successful execution of every project, from initial design and planning to installation and maintenance. We are committed to delivering high-quality results on time and within budget, helping our clients achieve their goals efficiently and effectively.
            </p>
        </div>
    </div>
</div> */}
        </section>
    );
};

export default Project2;