/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { SideMenu } from '../../api/SideMenu'
import { BlogApi2 } from '../../api/BlogApi/BlogApi2';
import axios from 'axios';
import API_BASE_URL from '../../api/API/baseUrl';
import API_IMAGE_URL from '../../api/API/baseImageUrl';
import parse from 'html-react-parser';




const BlogSection2 = ({ bgImage, name, category }) => {
    const { productLink } = useParams();
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [data, setData] = useState(null);
    const [sideMenuData, setSideMenuData] = useState(null);
    const [products, setProducts] = useState(null);
    const [allTablesData, setAllTablesData] = useState([])


    useEffect(() => {
        const fetchData = async () => {
            try {
                const productResponse = await axios.get(`${API_BASE_URL}/product?${productLink}`);
                setData(productResponse.data.data);

                const productSideMenu = await axios.get(`${API_BASE_URL}/product`);
                setSideMenuData(productSideMenu.data.data);

            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [productLink]);


    const getAllTables = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/table`);
            return response;
        } catch (error) {
            console.error('Error fetching all tables:', error);
            throw error;
        }
    };

    const getTableById = async (tableId) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/table?${tableId}`);
            return response;
        } catch (error) {
            console.error(`Error fetching table data for tableId ${tableId}:`, error);
            throw error;
        }
    };

    const fetchTableData = async () => {
        try {
            const allTablesResponse = await getAllTables();
            const allTables = allTablesResponse.data.data;

            const filteredTables = allTables.filter(table => table.product_id === parseInt(productLink));

            const tablesDataPromises = filteredTables.map(async (table) => {
                const tableDataResponse = await getTableById(table.table_id);
                return tableDataResponse.data;
            });

            const tablesData = await Promise.all(tablesDataPromises);
            setAllTablesData(tablesData.map(table => table.data));

        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    useEffect(() => {
        fetchTableData();
    }, [productLink]);

    const handleAccordionClick = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };

    const product = sideMenuData?.find(item => item.id === data.id);
    if (!product) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
                padding: '20px',
            }}>
                <div style={{
                    textAlign: 'center',
                    backgroundColor: 'black',
                    color: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                    fontSize: "24px"
                }}>
                    Product not found
                </div>
            </div>
        );
    }
    const filteredSideMenu = sideMenuData?.filter(item => item.category_id === data.category_id);

    const renderImages = (headingNumber) => {
        if (!data) return null;

        const images = [];
        for (let i = 2; data[`img${i}`]; i++) {
            const imgHeadingNumber = data[`img${i}`].match(/(\d+)/)[0];
            if (imgHeadingNumber === headingNumber) {
                images.push(
                    <div className="col-lg-4 mt-3 mb-3" key={i}>
                        <img
                            className="img-fluid"
                            src={API_IMAGE_URL + data[`img${i}`]}
                            alt={`Image ${i}`}
                            style={{ marginBottom: '10px', marginTop: "25px" }}
                        />
                    </div>
                );
            }
        }
        return images;
    };

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-9 mb-6 mb-lg-0 order-lg-1 ps-lg-5">
                        <div className="service-item">
                            <h2>{data.name}</h2>
                            <div className="row mt-5 mb-5">
                                <div className="col-lg-7 col-12 border-start ps-5 border-light d-flex align-items-center">
                                    <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'hidden', margin: 'auto' }}>
                                        <img src={API_IMAGE_URL + `${data.img1}`} alt={`Placeholder Image`} className="img-fluid" style={{ borderRadius: "25px" }} />
                                        {/* {data.img1 && <img src={data.img1} alt={data.name} className="mr-4" style={{ maxWidth: '100%', height: 'auto' }} />} */}
                                    </div>
                                </div>
                                <div className="col-lg-5 col-12 border-start ps-5 border-light">
                                    <div className='mt-5'>
                                        <h3>Technical Specification</h3>
                                        {data.productSpecifications && data.productSpecifications.length > 0 ? (
                                            <ul className="list-unstyled list-icon">
                                                {data.productSpecifications.map((spec, index) => (
                                                    <li key={index}>
                                                        <i className="las la-check-circle" />
                                                        {spec.title}: {spec.value}
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p>No technical specifications available.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <p className="mb-5">{parse(data.description)}</p>
                            <div className="mt-5">
                            {data && Object.keys(data).map((key) => {
                                if (key.startsWith("h")) {
                                    const headingNumber = key.slice(1);
                                    if (data[key] || data[`desc${headingNumber}`] || data[`desc${headingNumber}_2`] || renderImages(headingNumber).length > 0) {
                                        return (
                                            <div key={key} style={{ marginBottom: '20px' }}>
                                                <h3 className='mt-5 mb-2'>{data[key]}</h3>
                                                {data[`desc${headingNumber}`] && (
                                                    <p className='justify-text'>{parse(data[`desc${headingNumber}`])}</p>
                                                )}
                                                <div className="row">
                                                    {renderImages(headingNumber)}
                                                </div>
                                                {data[`desc${headingNumber}_2`] && (
                                                    <p className='justify-text'>{parse(data[`desc${headingNumber}_2`])}</p>
                                                )}
                                            </div>
                                        );
                                    } else {
                                        return null; 
                                    }
                                }
                                return null;
                            })}
                        </div>
                            <div className="accordion" id="accordion">
                                {(product.productDetails || []).map((item, index) => (
                                    <div className={`accordion-item bg-transparent mb-4 ${activeAccordion === item.id ? 'active' : ''}`} key={index}>
                                        <h3 className="accordion-header" id={`heading${index}`}>
                                            <button
                                                className={`accordion-button shadow-none mb-0 bg-transparent ca ${activeAccordion === item.id ? "" : "collapsed"}`}
                                                type="button"
                                                onClick={() => handleAccordionClick(item.id)}
                                            >
                                                {item.id}.{item.title}
                                            </button>
                                        </h3>
                                        <div
                                            id={`collapse${index}`}
                                            className={`accordion-collapse collapse ${activeAccordion === item.id ? "show" : ""}`}
                                            aria-labelledby={`heading${index}`}
                                            data-bs-parent="#accordion"
                                            style={{ transition: 'maxHeight 4s ease-in-out ', maxHeight: activeAccordion === item.id ? '500px' : '0' }}
                                        >
                                            <div className="accordion-body">{item.details}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>
                                {allTablesData && allTablesData.map((tableData, index) => (
                                    <div key={index} style={{ overflowX: 'auto', marginBottom: '25px', marginTop: '25px' }}>
                                        <h3>{tableData.name}</h3>
                                        <table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid #ddd' }}>
                                            <thead>
                                                <tr>
                                                    {tableData.columns.map((column) => (
                                                        // Check if there are any cells in this column
                                                        tableData.cells.some(cell => cell.column_id === column.column_id) ? (
                                                            <th key={column.column_id} style={{ width: `${column.column_width}px` }}></th>
                                                        ) : null
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.rows.map((row) => (
                                                    tableData.cells.filter(cell => cell.row_id === row.row_id).length > 0 ? (
                                                        <tr key={row.row_id} style={{ height: `${row.row_height}px` }}>
                                                            {tableData.cells
                                                                .filter((cell) => cell.row_id === row.row_id)
                                                                .map((cell) => (
                                                                    <td
                                                                        key={cell.column_id}
                                                                        rowSpan={cell.row_span}
                                                                        colSpan={cell.col_span}
                                                                        style={{ padding: '8px', border: '1px solid #ddd' }}
                                                                    >
                                                                        {cell.cell_content === '' ? <>&nbsp;</> : cell.cell_content}
                                                                    </td>
                                                                ))}
                                                        </tr>
                                                    ) : null
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </div>


                        </div>
                        <div className="accordion mt-8" id="accordion">
                            {BlogApi2.map((item, index) => (
                                <div className={`accordion-item bg-transparent mb-4 ${activeAccordion === item.id ? 'active' : ''}`} key={index}>
                                    <h3 className="accordion-header" id={`heading${index}`}>
                                        <button
                                            className={`accordion-button shadow-none mb-0 bg-transparent ca ${activeAccordion === item.id ? "" : "collapsed"}`}
                                            type="button"
                                            onClick={() => handleAccordionClick(item.id)}
                                        // aria-expanded={activeAccordion === index}
                                        // aria-controls={`collapse${index}`}
                                        >
                                            {item.id}.{item.title}
                                        </button>
                                    </h3>
                                    <div
                                        id={`collapse${index}`}
                                        className={`accordion-collapse collapse ${activeAccordion === item.id ? "show" : ""}`}
                                        aria-labelledby={`heading${index}`}
                                        data-bs-parent="#accordion"
                                        style={{ transition: 'maxHeight 4s ease-in-out ', maxHeight: activeAccordion === item.id ? '500px' : '0' }}
                                    >
                                        <div className="accordion-body">{item.details}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="service-widget widget widget_nav_menu">
                            <ul id="menu-services-menu" className="menu mb-0">
                                {filteredSideMenu?.map((service, index) => (
                                    <li style={{ padding: "5px" }} key={service.id} className={`menu-item ${service.link === `/${productLink}` ? 'current-menu-item' : ''}`}>
                                        <Link to={`/products/${service.id}`}>{service.name}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default BlogSection2;