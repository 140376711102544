import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CarouselApi2 from "../../api/CarouselApi/CarouselApi2";


const Carousel2 = ({ bg1, dataOver, bgColor }) => {
  const options = {
    dots: false,
    nav: true,
    items: 1,
    autoplay: true,
    loop: true,
  };
  return (
    <section style={{ backgroundImage: `url(${bg1})`, paddingTop: "15px", paddingBottom: "15px" }} data-overlay={dataOver} className={`${bgColor}`}>
      <div className="container">
        <div className="row">
          <div className="col">
            <OwlCarousel className="owl-carousel no-pb" {...options} navText={[
              "<span class='owl-nav-icon las la-long-arrow-alt-left' style={{ backgroundColor: 'white' }}></span>",
              "<span class='owl-nav-icon las la-long-arrow-alt-right' style={{ backgroundColor: 'white' }} ></span>"
            ]}>
              {CarouselApi2.map((testimonial, index) => (
                <div className="item" key={index}>
                  <div className="testimonial style-1" style={{ textAlign: "center" }}>
                    <div className="testimonial-img" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <img className="img-fluid" style={{ width: "150px", height: "150px", borderRadius: "50%" }} src={testimonial.img} alt="Image1" />
                      <div className="testimonial-caption" style={{ textAlign: "center", marginTop: "10px" }}>
                        <h5 style={{ margin: 0 }}>{testimonial.name}</h5>
                        <span>{testimonial.position}</span>
                      </div>
                    </div>
                    <div className="testimonial-content" style={{ marginTop: "20px" }}>
                      <div className="testimonial-quote" style={{ marginBottom: "10px" }}>
                        <i className="flaticon flaticon-quotes"></i>
                      </div>
                      <p style={{ fontSize: "20px", textAlign: "justify", wordSpacing: "-1px" }}>{testimonial.description}</p>
                    </div>
                  </div>

                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Carousel2;
