import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link, useNavigate } from 'react-router-dom';
import TeamMembersApi from '../../api/TeamMembersApi/TeamMembersApi';
import ProductList2API from '../../api/TeamMembersApi/ProductList2API';
import { NavLink } from 'react-router-dom';
import Odometer from 'react-odometerjs';
import ProgressBar from '../common/ProgressBar';
import BannerAdd2 from '../../Components/bannerAdd/BannerAdd2'
import Project1 from '../../Components/projectSection/Project1'


const TeamMember = () => {
    const [value, setValue] = useState(0);
    const [limit, setLimit] = useState(false)
    const handleInfinitescroll = async () => {
        // console.log("scrollTop" + document.documentElement.scrollTop); 
        try {
            if (document.documentElement.scrollTop >= 200) {
                setLimit(true)
            }
            else if (document.documentElement.scrollTop === 0) {
                setLimit(false)
            }
        } catch (error) {
            console.log(error);
        }

    }
    // scrollToTop
    useEffect(() => { window.addEventListener('scroll', handleInfinitescroll, { passive: true }) }, [])
    useEffect(() => {
        const timeoutId = setTimeout(() => setValue(23), 1000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);
    const navigate = useNavigate();
    const SinglePage = (member) => {
        localStorage.setItem('team', JSON.stringify(member));
        navigate(`/team/${member.id}`)
    }
    return (
        <section className="pt-0 mt-5">
            <div className="container">
             <div className="row justify-content-center text-center mb-5">
                    <div className="col-lg-8 col-md-12">
                        <div className="theme-title">
                            <h6>Leading Experts in Dewatering Pumps & Spares</h6>
                            <h2>Providing Innovative Solutions and Exceptional Service</h2>
                        </div>
                    </div>
                </div>
                {/* <div className="row ">
                    <div className="theme-title">
                        <h6 style={{ fontSize: "20px" }}>Dewatering Pumps & spare parts</h6>
                    </div>
                    <div className="col d-flex justify-content-evenly">
                        <OwlCarousel
                            className="owl-carousel"
                            items={4}
                            loop={true}
                            responsive={{
                                0: { items: 1 },
                                576: { items: 2 },
                                768: { items: 3 },
                                992: { items: 4 },
                            }}
                            dots={false}
                            nav
                            autoplay
                            margin={20}
                            navText={[
                                "<span class='owl-nav-icon las la-long-arrow-alt-left' style={{ backgroundColor: 'white' }}></span>",
                                "<span class='owl-nav-icon las la-long-arrow-alt-right' style={{ backgroundColor: 'white' }} ></span>"
                            ]}
                        >
                            {TeamMembersApi.map((member, index) => (
                                <div className="item" key={index}>
                                    <div className="team-member " >
                                        <h4 className="member-name text-decoration-none" onClick={() => SinglePage(member)}>
                                            <NavLink>{member.name} </NavLink>
                                        </h4>

                                        <ul className="team-social-icon list-inline d-flex justify-content-start">
                                            {member.social.map((icon, i) => (
                                                <li key={i} className="pe-1">
                                                    <Link to="">
                                                        <i className={`la la-${icon}`}></i>
                                                    </Link>
                                                </li>
                                            ))} 
                                        </ul>
                                        <div className="team-images">
                                            <img src={member.image} style={{ height: "250px" }} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div> 
               
                {/* <div className="row mt-5">
                    <div className="theme-title">
                        <h6 style={{ fontSize: "20px" }}>Hoses</h6>
                    </div>
                    <div className="col d-flex justify-content-evenly" style={{ width: "300px" }}>
                        <OwlCarousel
                            className="owl-carousel"
                            items={3}
                            loop={true}
                            responsive={{
                                0: { items: 1 },
                                576: { items: 2 },
                                768: { items: 3 }
                            }}
                            dots={false}
                            nav
                            autoplay
                            margin={20}
                            navText={[
                                "<span class='owl-nav-icon las la-long-arrow-alt-left' style={{ backgroundColor: 'white' }}></span>",
                                "<span class='owl-nav-icon las la-long-arrow-alt-right' style={{ backgroundColor: 'white' }} ></span>"
                            ]}
                        >
                            {ProductList2API.map((member, index) => (
                                <div className="item" key={index}>
                                    <div className="team-member " >
                                        <h4 className="member-name text-decoration-none" onClick={() => SinglePage(member)}>
                                            <NavLink>{member.name} </NavLink>
                                        </h4>


                                        <div className="team-images">
                                            <img src={member.image} style={{ height: "250px" }} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div> */}
               {/*  <div className="row align-items-center mt-5" >
                    <div className="col-12 col-lg-6 position-relative mt-5">
                        <div className="row">
                            <div className="col-md-6 mt-10  de-watering">
                                <img style={{height:"450px"}} src={require("../../assets/images/about/03.webp")} className="img-fluid rounded" alt="Img3" />
                            </div>
                            <div className="col-md-6  de-watering-img">
                                <img style={{height:"450px"}} src={require("../../assets/images/about/04.jpg")} className="img-fluid rounded" alt="Img4" />
                                <NavLink to={"/about-us"}  className="primary-btn text-decoration-none mt-4">Learn More</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 ps-lg-5 mt-5 mt-lg-0">
                        <div className="theme-title ">
                        
                        <h2>Expert Solutions in Dewatering Pumps</h2>
                            <p>Dewatering pumps are medium-head and high flow design has been developed to operate construction site, flood water draining, mining, and sewer by-pass environments.
</p>
                        </div>
                        <div className="row mt-4 align-items-center">
                            <h6>Technical Specification</h6>
                            <div className='col-lg-6'>
                                <ul className="list-unstyled list-icon">

                                    <li >
                                        <i className="las la-check-circle" /> Total Head : 25 mtrs
                                    </li>
                                    <li >
                                        <i className="las la-check-circle" /> Vacuum lift : 8.5 mtrs
                                    </li>
                                    <li >
                                        <i className="las la-check-circle" /> Volute Housing Cast Iron / SS
                                    </li>
                                    <li >
                                        <i className="las la-check-circle" /> Impeller : Stainless Steel

                                    </li>
                                    <li >
                                        <i className="las la-check-circle" /> Stuffing Box : Stainless Steel
                                    </li>

                                </ul>
                            </div>
                            <div className='col-lg-6'>
                                <ul className="list-unstyled list-icon">

                                    <li >
                                        <i className="las la-check-circle" /> Shaft : Stainless Steel

                                    </li>
                                    <li >
                                        <i className="las la-check-circle" /> Vauum Motor Heavy Duty 70 CFM with Original Parts

                                    </li>
                                    <li >
                                        <i className="las la-check-circle" /> Mechanical Seal : John Crane (U.K)
                                    </li>
                                   

                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
          
          {/* <BannerAdd2 name={"Our Expertise"} name2={"Featured Projects"} name3={"Case Studies"} /> */}

         <div>
         <Project1 />
         </div>
            
            </div>
        </section>
    );
};

export default TeamMember;