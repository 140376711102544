import testimonial from "../../assets/images/testimonial/01.jpg";
import testimonial1 from "../../assets/images/testimonial/09.jpg";
import testimonial2 from "../../assets/images/testimonial/10.jpg";
import testimonial3 from "../../assets/images/testimonial/11.jpg";
import testimonial4 from "../../assets/images/testimonial/12.jpg";


const CarouselApi2 = [
  {
    name: "John Smith",
    position: "Construction Manager",
    icon: "flaticon-quotes",
    description:
      "Logic Fusion LLC has been our go-to supplier for dewatering pumps and spares for several years now. Their products are of excellent quality, and their customer service is unmatched. We highly recommend them for anyone in need of reliable dewatering solutions.",
    img: testimonial4,
  },
  {
    name: "Emily Johnson",
    position: "Project Engineer",
    icon: "flaticon-quotes",
    description:
      "We've been partnering with Logic Fusion LLC for our foundry needs, and they have consistently delivered top-notch products. Their attention to detail and commitment to quality are truly commendable. It's a pleasure doing business with them.",
    img: testimonial1,
  },
  {
    name: "David Martinez",
    position: "Operations Director",
    icon: "flaticon-quotes",
    description:
      "Logic Fusion LLC has played a crucial role in our project's success by providing us with reliable dewatering solutions. Their team is knowledgeable, responsive, and always goes above and beyond to meet our requirements. We're grateful for their partnership.",
    img: testimonial3,
  },
  {
    name: "Sarah Thompson",
    position: "Site Supervisor",
    icon: "flaticon-quotes",
    description:
      "I've been consistently impressed with Logic Fusion LLC's dedication to customer satisfaction. Their expertise in dewatering pumps and spares is evident in the quality of their products. I wouldn't hesitate to recommend them to others in the industry.",
    img: testimonial2,
  },
];

export default CarouselApi2;