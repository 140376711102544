import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import CarouseApi3 from '../../api/CarouselApi/CarouseApi3';
import ProjectApi3 from '../../api/ProjectApi/ProjectApi3';
import axios from 'axios';
import API_BASE_URL from '../../api/API/baseUrl';
import API_IMAGE_URL from '../../api/API/baseImageUrl';

const Carousel5 = () => {
    const [servicesItems, setServicesItems] = useState([]);
    const [categoryId, setCategoryId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const galleryResponse = await axios.get(`${API_BASE_URL}/gallery`);

                const servicesData = galleryResponse.data.data.find(category => category.name === "Services");
                const categoryId = servicesData ? servicesData.id : null;
                setCategoryId(categoryId);

                const items = servicesData ? servicesData.subcategories.flatMap(subcategory =>
                    subcategory.items.map(item => ({
                        ...item,
                        subcategoryId: subcategory.id
                    }))
                ) : [];
                setServicesItems(items);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <OwlCarousel
            className="owl-carousel"
            items={3}
            loop={true}
            dots={true}
            responsive={{
                0: { items: 1 },
                576: { items: 1 },
                768: { items: 2 },
                992: { items: 2 },
                1200: { items: 3 }
            }}
            margin={30}
            autoplay
        >
            {servicesItems.map((item, index) => (
                <Link key={index} to={`/gallery/${categoryId}/${item.subcategoryId}/${item.id}`} className="item" style={{ textDecoration: 'none' }}>
                    <div className="service-item style-2" style={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.3s',
                        backgroundColor: '#fff',
                        position: 'relative',
                        textAlign: 'center',
                    }}>
                        <div className="service-image" style={{
                            width: '100%',
                            height: '200px',
                            overflow: 'hidden',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <img
                                src={API_IMAGE_URL + item.images[0]?.image || 'https://via.placeholder.com/350x150'}
                                className="img-fluid"
                                alt={item.title}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    transition: 'transform 0.3s',
                                    opacity: '0.8',
                                }}
                            />
                            <div style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                padding: '10px',
                                borderRadius: '5px',
                                width: '80%',
                            }}>
                                <h4 style={{
                                    color: 'black',
                                    textDecoration: 'none',
                                    margin: 0,
                                }}>{item.title || ''}</h4>
                            </div>
                        </div>
                    </div>
                </Link>
            ))}
        </OwlCarousel>
    );
}

export default Carousel5;