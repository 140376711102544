import React from 'react'
import HeroSection4 from '../../Components/heroSection/HeroSection4'
import BlogSingle from '../../Components/blogSection/BlogSingle'
import ServiceSinglePage from './ServiceSinglePage'

const ServiceDetailsPage = () => {
    return (
        <>
            <HeroSection4 name={"Our Service"} name2={"Home"} />
            <div class="page-content">
                <ServiceSinglePage />
            </div>
        </>
    )
}

export default ServiceDetailsPage;
